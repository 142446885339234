import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import { Button, BbotToggle } from "bbot-component-library";
import {
  trackClickBulkEditableSettingToggle,
  trackClickBulkEditSetting,
} from "instrumentation/tracking/page-tracking-events";
import { DescriptionContainer, SettingsContainer, TitleRow, ToggleContainer } from "styles/SettingsContainerStyles";

const BulkEditableSetting = (props) => {
  const {
    title,
    description,
    inBulkEditMode = false,
    onBulkEditInitial,
    onBulkEditCancel,
    bulkEditButtonText,
    toggleConfig,
    className = "",
  } = props;

  const [dismissed, setDismissed] = useState(false);
  const [toggleEnabled, setToggleEnabled] = useState(false);

  const toggleDefaultEnabled = toggleConfig?.defaultEnabled;

  useEffect(() => {
    if (!!toggleDefaultEnabled) {
      setToggleEnabled(toggleDefaultEnabled);
    }
  }, [toggleDefaultEnabled]);

  const showToggle = !!toggleConfig && (!inBulkEditMode || (inBulkEditMode && dismissed));
  const showContent =
    (!inBulkEditMode || (inBulkEditMode && dismissed)) && // dismissed part ok: not in bulk edit mode or in bulk edit mode and has dismissed
    (!toggleConfig || (toggleConfig?.toggleShouldBlockContent && toggleEnabled)); // toggle part ok: no toggle config or toggle should block and is enabled

  return (
    <SettingsContainer className={"settings-element"}>
      <TitleRow className={className}>
        <span>{title}</span>
        <ToggleContainer>
          <BbotToggle
            hidden={!showToggle}
            defaultEnabled={toggleConfig?.defaultEnabled}
            onClick={(e) => {
              if (toggleConfig?.onClick) {
                toggleConfig.onClick(e);
              }
              trackClickBulkEditableSettingToggle({ setting_name: title });
            }}
            onStateChanged={(checked) => {
              setToggleEnabled(checked);
            }}
          />
          {inBulkEditMode && (
            <Button
              type={"link"}
              onClick={() => {
                if (dismissed) {
                  onBulkEditCancel();
                } else {
                  onBulkEditInitial(toggleEnabled);
                  trackClickBulkEditSetting({ setting_name: title, setting_description: description });
                }
                setDismissed((dismissed) => !dismissed);
              }}
            >
              {dismissed ? "Cancel Changes" : bulkEditButtonText}
            </Button>
          )}
        </ToggleContainer>
      </TitleRow>
      <DescriptionContainer>
        <i>{description}</i>
      </DescriptionContainer>
      {showContent && <div>{props.children}</div>}
    </SettingsContainer>
  );
};

BulkEditableSetting.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.element]),
  inBulkEditMode: PropTypes.bool.isRequired,
  onBulkEditInitial: PropTypes.func,
  onBulkEditCancel: PropTypes.func,
  bulkEditButtonText: PropTypes.string,
  toggleConfig: PropTypes.object,
};

export default BulkEditableSetting;

/* eslint-disable react-hooks/exhaustive-deps */
import { Icon, StackChildren, Text } from "@doordash/design-language";
import Button from "@doordash/component-button";
import Toggle from "@doordash/component-toggle";
import Loading from "@doordash/component-loading";
import { LinkSectionText, UpsellsListSectionTitle } from "./styles";
import { toggleItem } from "./utils";
import { sortBy } from "lodash";
import { MenuHeadingUpsells, MenuItemUpsells } from "./MenuHeadingAndItemUpsells";
import { Breadcrumbs } from "bbot-component-library";

const { Medium18, Bold18, Bold32, Medium12, Medium16 } = Text.Styles;

const CartUpsellsLink = ({ cartLevelUpsellsSelected, loading, setUpsells }) =>
  loading ? (
    <Loading />
  ) : (
    <LinkSectionText
      sectionTitle
      onClick={() => setUpsells("")}
      selected={cartLevelUpsellsSelected}
      styles={cartLevelUpsellsSelected ? Bold18 : Medium18}
    >
      Cart Upsells
    </LinkSectionText>
  );

export const LinkSectionTexts = ({
  cartLevelUpsellsSelected,
  loading,
  menuHeadings,
  menuItems,
  menus,
  selectedElement,
  setUpsells,
}) => (
  <StackChildren>
    <CartUpsellsLink loading={loading} cartLevelUpsellsSelected={cartLevelUpsellsSelected} setUpsells={setUpsells} />
    <MenuHeadingUpsells menuHeadings={menuHeadings} selectedElement={selectedElement} setUpsells={setUpsells} />
    <MenuItemUpsells menuItems={menuItems} menus={menus} selectedElement={selectedElement} setUpsells={setUpsells} />
  </StackChildren>
);

export const UpsellsList = ({
  currentlySelectedUpsells,
  noElementSelected,
  orderedUpsells,
  setCurrentlySelectedUpsells,
  setOrderedUpsells,
  textToDisplay,
}) => (
  <StackChildren>
    <Text styles={Medium18}>
      <UpsellsListSectionTitle>
        {noElementSelected && <Icon type={Icon.Types.ArrowLeft} />}
        <Text styles={Bold18}>
          {noElementSelected ? "Select Element to Configure" : `Select Upsells For This ${textToDisplay}`}
        </Text>
      </UpsellsListSectionTitle>
      {!noElementSelected &&
        currentlySelectedUpsells.length !== 0 &&
        sortBy(currentlySelectedUpsells, "name").map((menuItem) => (
          <Toggle
            key={menuItem.id}
            type={Toggle.Types.Checkbox}
            isSelected={menuItem.selected}
            onChange={() =>
              toggleItem(
                menuItem,
                currentlySelectedUpsells,
                setCurrentlySelectedUpsells,
                orderedUpsells,
                setOrderedUpsells
              )
            }
            label={menuItem.name}
          />
        ))}
    </Text>
  </StackChildren>
);

export const CarouselItemOrder = ({ orderedUpsells, saveUpsells }) => (
  <StackChildren>
    <Text styles={Bold18}>Carousel Item Order</Text>
    {orderedUpsells.length !== 0 &&
      orderedUpsells.map((upsell) => (
        <Text styles={Medium12}>
          {orderedUpsells.indexOf(upsell) + 1}. {upsell.name}
        </Text>
      ))}
    <Button onClick={saveUpsells}>Save</Button>
  </StackChildren>
);

export const UpsellsHeading = () => (
  <>
    <Breadcrumbs name={"Upsells"} link={"/upsells"} />
    <Text styles={Bold32}>Upsells For Current Merchant</Text>
    <br />
    <Text styles={Medium16}>
      The upsells will only be visible if configured properly, currently fulfillable, enabled, in-stock, and associated
      with menus and menu headings that are also enabled and otherwise properly configured. Unintended behavior on the
      site is likely to be a result of configuration gaps.
    </Text>
    <br />
  </>
);

import { DoveTailIcons } from "./utils";

export const DISABLED_TEXT_COLOR_HEX = "#92a4bc";
export const DEFAULT_TEXT_COLOR_HEX = "#475569";
export const DOVETAIL_ANNOUNCEMENTS = [
  {
    header: "Bbot v2 has been released!",
    body: "As Bbot integrates with DoorDash, some features will work differently. Tooltips will display next to pages with changes, please see them for more information",
  },
];
const MERCHANT_PORTAL_DEFAULT_URL = "https://www.doordash.com/merchant";
const MERCHANT_PORTAL_REPORTS_URL = "https://www.doordash.com/merchant/reports";
const MERCHANT_PORTAL_REFUNDS_URL = "https://www.doordash.com/merchant/bank-account";

export const dovetailFlaggedLinks = [
  {
    name: "Bbot App Store", // React page (Changes: All integrations that don't have “Paytronix”, “Toast”, or “Gift Card” in their title are not shown. Show yellow alert with tooltip text at the top of page.)
    iconType: DoveTailIcons.Warning,
    isButtonDisabled: false,
    redirectURL: null,
    alertRedirectURL: "",
    tooltipBodyText: "Only Paytronix, Toast, and Gift Card integrations are now supported.",
  },
  {
    name: "Promos + Discounts", // React page (Changes: User will no longer be able to create discounts, but can create promos. Show alert with tooltip text at top of page)
    iconType: DoveTailIcons.Info,
    isButtonDisabled: false,
    redirectURL: null,
    alertRedirectURL: "",
    tooltipBodyText: "Logged-in user discounts will no longer be supported but promo codes will still be available.",
  },
  {
    name: "Account Settings", // Angular page
    iconType: DoveTailIcons.Info,
    isButtonDisabled: false,
    redirectURL: null,
    alertRedirectURL: "",
    tooltipBodyText:
      "Configuring Checkout Limitations and Sales Tax will no longer be supported. Sales Tax will be configurable in the POS.",
  },
  {
    name: "Past Orders", // React page (Changes: When looking at order details, “Issue Refund” button is disabled. Show blue alert with tooltip text at top of page.)
    iconType: DoveTailIcons.Info,
    isButtonDisabled: false,
    redirectURL: null,
    alertRedirectURL: "",
    tooltipBodyText: "Issuing Refunds will no longer be supported but can still be accomplished in the POS.",
  },
  {
    name: "Reports", // Angular page
    iconType: DoveTailIcons.Warning,
    isButtonDisabled: false,
    redirectURL: MERCHANT_PORTAL_REPORTS_URL,
    alertRedirectURL: "",
    tooltipBodyText:
      "Reports will now be configured in the DoorDash Merchant Portal. Please click the Reports button to be redirected.",
  },
  {
    name: "Support Text Alerts", // Angular page
    iconType: DoveTailIcons.Warning,
    isButtonDisabled: true,
    redirectURL: null,
    alertRedirectURL: "",
    tooltipBodyText: "Support Text Alerts will no longer be supported.",
  },
  {
    name: "Guest Notification Sets", // React page (Changes: When creating a new configuration, the stations button is disabled and alert is only shown at the top of the flow. Alert is yellow with tooltip text. When editing or previewing configurations, station config buttons are removed.)
    iconType: DoveTailIcons.Info,
    isButtonDisabled: false,
    redirectURL: null,
    alertRedirectURL: "",
    tooltipBodyText:
      "Guest Notification Sets for Stations will no longer be supported but configurations for Locations are still available.",
  },
  {
    name: "Printer Configuration", // React page (Changes: User will no longer be able to access this page by clicking on button or manually inserting url)
    iconType: DoveTailIcons.Warning,
    isButtonDisabled: true,
    redirectURL: null,
    alertRedirectURL: "",
    tooltipBodyText: "Printer Configurations will no longer be supported.",
  },
  {
    name: "Payouts and Billing", // React page (Changes: On the Stripe Payout Settings tab, user can no longer see or configure any settings and is instead shown alert with tooltip text at top of page.)
    iconType: DoveTailIcons.Info,
    isButtonDisabled: false,
    redirectURL: null,
    alertRedirectURL: MERCHANT_PORTAL_REFUNDS_URL,
    tooltipBodyText:
      "Stripe Payout Settings will no longer be supported but will be configurable in the DoorDash Merchant Portal.",
  },
  {
    name: "PreBot", // React page (Changes: When user is on "Bbot's Onboarding Helper" page with list of journeys, show alert with tooptip text. When user is inside the "Journey" flow, on the "Getting Started" tab and under the "Payouts" section: all config options are removed and is instead shown alert with tooltip text.)
    iconType: DoveTailIcons.Info,
    isButtonDisabled: false,
    redirectURL: null,
    alertRedirectURL: MERCHANT_PORTAL_REFUNDS_URL,
    tooltipBodyText:
      "Payout configuration will no longer be supported but will be configurable in the DoorDash Merchant Portal.",
  },
  {
    name: "Menus", // Angular page
    iconType: DoveTailIcons.Info,
    isButtonDisabled: false,
    redirectURL: null,
    alertRedirectURL: "",
    tooltipBodyText:
      "Configuring the Sales Tax for individual items will no longer be supported but will be configurable in the DoorDash Merchant Portal.",
  },
  {
    name: "Cut Agreements", // React page (Changes: User will no longer be able to access this page by clicking on button or manually inserting url)
    iconType: DoveTailIcons.Warning,
    isButtonDisabled: true,
    redirectURL: null,
    alertRedirectURL: "",
    tooltipBodyText: "Cut Agreements will no longer be supported.",
  },
  {
    name: "Customer Properties", // Angular page
    iconType: DoveTailIcons.Info,
    isButtonDisabled: false,
    redirectURL: null,
    alertRedirectURL: "",
    tooltipBodyText:
      "Restaurant Chain Config will no longer be supported, but all other configurations will remain supported.",
  },
  {
    name: "Stations", // React page (Changes: User will no longer be able to access this page by clicking on button or manually inserting url)
    iconType: DoveTailIcons.Info,
    isButtonDisabled: true,
    redirectURL: null,
    alertRedirectURL: "",
    tooltipBodyText: "Station configurations will no longer be supported.",
  },
];

///////////////////////////////////////////////////////////////////////////////
// Helpers ////////////////////////////////////////////////////////////////////

export const isLinkFlagged = (targetLinkName) => {
  const info = getDovetailInfoForLink(targetLinkName);
  if (!info) return false;
  return true;
};

export const isIntegrationFlaggedForDoveTail = (integrationName, isOwnerFlagged) => {
  if (!isOwnerFlagged) return true; // If user is not flagged, then return true for all integrations
  if (!integrationName) return false; // If integration name is undefined (can happen with partners), return false

  return (
    integrationName.toLowerCase().includes("paytronix") ||
    integrationName.toLowerCase().includes("toast") ||
    integrationName.toLowerCase().includes("gift card")
  );
};

export const getDovetailInfoForLink = (targetLinkName) => {
  return dovetailFlaggedLinks.find((flaggedLink) => {
    return flaggedLink.name === targetLinkName;
  });
};

export const getDovetailButtonDisabled = (targetLinkName) => {
  const doveTailInfo = getDovetailInfoForLink(targetLinkName);
  if (!doveTailInfo) return "";
  return doveTailInfo.isButtonDisabled;
};

export const getDovetailRedirectLink = (targetLinkName, isOwnerFlagged, selectedCustomerDDStoreID) => {
  const doveTailInfo = getDovetailInfoForLink(targetLinkName);
  if (!doveTailInfo || !isOwnerFlagged || !doveTailInfo.redirectURL) return null;
  // If the customer has a storeID for the DD Mx Portal, then redirect them there, otherwise redirect to regular URL to trigger sign-in
  if (selectedCustomerDDStoreID) {
    return `${doveTailInfo.redirectURL}?store_id=${selectedCustomerDDStoreID}`;
  }
  return MERCHANT_PORTAL_DEFAULT_URL;
};

export const getDovetailClassnames = (targetLinkName, isOwnerFlagged, isSuperAdmin) => {
  const doveTailInfo = getDovetailInfoForLink(targetLinkName);
  if (!doveTailInfo || !isOwnerFlagged) return "";
  let disabledColor = doveTailInfo.isButtonDisabled ? "apply-disabled-color" : "";
  disabledColor = isSuperAdmin ? "apply-super-admin-disabled-color" : disabledColor;
  const iconMargin = doveTailInfo ? "apply-margin-for-icon" : "";
  const doveTailClassNames = `${disabledColor} ${iconMargin}`;

  return doveTailClassNames;
};

export const getDoveTailToolTipBodyText = (targetLinkName, isOwnerFlagged) => {
  const doveTailInfo = getDovetailInfoForLink(targetLinkName);
  if (!doveTailInfo || !isOwnerFlagged) return "";
  return doveTailInfo.tooltipBodyText;
};

export const getDoveTailAlertTextWithRedirect = (targetLinkName, isOwnerFlagged, selectedCustomerDDStoreID) => {
  const doveTailInfo = getDovetailInfoForLink(targetLinkName);
  if (!doveTailInfo || !isOwnerFlagged) return "";
  // If the customer has a storeID for the DD Mx Portal, then redirect them there, otherwise redirect to regular URL to trigger sign-in
  const url =
    selectedCustomerDDStoreID !== "" && selectedCustomerDDStoreID !== undefined && selectedCustomerDDStoreID !== null
      ? `${doveTailInfo.alertRedirectURL}?store_id=${selectedCustomerDDStoreID}`
      : MERCHANT_PORTAL_DEFAULT_URL;

  return `${doveTailInfo.tooltipBodyText} <a target='_blank' href='${url}'>Please click this link to be redirected there.</a>`;
};

export const getDovetailIconType = (targetLinkName) => {
  const doveTailInfo = getDovetailInfoForLink(targetLinkName);
  if (!doveTailInfo) return "";
  return doveTailInfo.iconType;
};

import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { BbotButton, notification, Steps, Row, BbotModal } from "bbot-component-library";
import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";
import PropTypes from "prop-types";
import { splitCamelCase } from "../util/Utils";

const BbotWizard = ({
  userFlowSteps,
  stepContents,
  userFlow = Object.keys(userFlowSteps)[0],
  firstStep = userFlowSteps[Object.keys(userFlowSteps)[0]][0],
  mode = "add",
  resetFunction,
  confirmationTitle,
  confirmationDescription,
  confirmationFunction,
  editFunction = () => void 0,
  stepToStartAtWhenEditing = userFlowSteps[Object.keys(userFlowSteps)[0]][0],
  stepsForbiddenToReturnTo = [],
  userFlowsPrettyNames,
  secondBreadcrumbToDisplay = "",
  disallowedReturnMessage = "Sorry, you cannot return to this step.",
  icon,
  setUserFlow,
  isSelectedCustomerFlaggedForDovetail,
}) => {
  /**
   * Wizards are a series of steps used to create a new object. Each step has a "Cancel" and "Next" button at the bottom with customizes content in the middle.
   *
   * userFlowSteps: {userFlow : ['step1', 'step2', 'step3']}, map of user flow keys to a list of creation steps for that user flow
   * stepContents {'step1': {
   *   title: str, title displayed for step
       content: React component, all content displayed for a certain step
       nextStepFunction: () => {}, can be empty, wizard will automatically go to next step, but if you want more action to take place, this is where to put it
       disabled: bool, conditions under which 'Next' button should be disabled
       form: ant design Form instance, should be used when a creation step is entirely a form with the 'Next' button acting as the submit button
   * }}
   * userFlow: str, current user flow key
   * firstStep: str, step key that the
   * mode: str, 'edit' or 'add'
   * resetFunction: func, action taken when the Cancel button is pressed
   * confirmationTitle: str, title of confirmation modal that pops up when the wizard is finished
   * confirmationDescription: str, body of confirmation modal that pops up when the wizard is finished
   * confirmationFunction: func, final action taken to save the object created by the wizard
   * editFunction: func, action taken when pressing the 'Save Changes' button that appears in 'edit' mode
   * stepToStartAtWhenEditing: str, must correspond to keys in userFlowSteps / stepContents. Can be first step in creation but sometimes we need to start at a later step in 'edit' mode
   * stepsForbiddenToReturnTo: lst[str], step keys that, once completed, cannot be returned to.
   * userFlowsPrettyName: {}, map of userFlow keys to pretty names. Displayed in first breadcrumb once user flow is selected
   * secondBreadcrumbToDisplay: str, value to display in second breadcrumb, should be something overall descriptive about the object being created / edited
   * icon: SVG component for display, not required
   * disallowedReturnMessage: Message to display when returning to a disallowed previous step
   * isSelectedCustomerFlaggedForDovetail: used for doveTail integration. We need to change behavior for customers using dovetail
 */

  const [creationStep, setCreationStep] = useState(firstStep);
  const [editableSteps, setEditableSteps] = useState([]);
  const [currentStepIndex, setCurrentStepIndex] = useState(0);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [completedSteps, setCompletedSteps] = useState([]);

  // Default behavior uses employee, if customer is flagged for dovetail we only want promoCodes allowed
  const defaultUserFlow = isSelectedCustomerFlaggedForDovetail ? "promoCode" : "employee";

  if (!userFlowsPrettyNames) {
    userFlowsPrettyNames = {};
    Object.keys(userFlowSteps).forEach((stepKey) => (userFlowsPrettyNames[stepKey] = splitCamelCase(stepKey)));
  }

  useEffect(() => {
    if (setUserFlow && !userFlow) {
      setUserFlow(defaultUserFlow);
    }
    if (mode === "edit") {
      setCreationStep(stepToStartAtWhenEditing);
      setEditableSteps(
        userFlowSteps[defaultUserFlow].slice(userFlowSteps[defaultUserFlow].indexOf(stepToStartAtWhenEditing))
      );
      setCurrentStepIndex(userFlowSteps[defaultUserFlow].indexOf(stepToStartAtWhenEditing));
    } else if (mode === "add") {
      setEditableSteps([firstStep]);
      setCurrentStepIndex(0);
      setCreationStep(firstStep);
    }
  }, [mode]); // eslint-disable-line react-hooks/exhaustive-deps

  const goToPreviousCreationStep = () => {
    const previousStep = userFlowSteps[userFlow].indexOf(creationStep) - 1;
    if (previousStep === -1) {
      // reached the first step
      resetFunction();
    } else if (stepsForbiddenToReturnTo.includes(userFlowSteps[userFlow][previousStep])) {
      notification.warning({
        message: disallowedReturnMessage,
      });
    } else {
      // go to next step
      setCurrentStepIndex(previousStep);
      setCreationStep(userFlowSteps[userFlow][previousStep]);
    }
  };

  const goToNextCreationStep = (nextStepFunction) => {
    if (nextStepFunction) nextStepFunction();
    const nextStep = userFlowSteps[userFlow].indexOf(creationStep) + 1;
    if (nextStep === userFlowSteps[userFlow].length) {
      setCompletedSteps(userFlowSteps[userFlow]);
      setShowConfirmationModal(true);
    } else {
      // go to next step
      if (mode === "add")
        setCompletedSteps((oldSteps) => {
          return oldSteps.concat(userFlowSteps[userFlow][currentStepIndex]);
        });
      setCreationStep(userFlowSteps[userFlow][nextStep]);
      setCurrentStepIndex(nextStep);
      let newEditableSteps =
        mode === "add"
          ? userFlowSteps[userFlow].slice(0, nextStep + 1)
          : userFlowSteps[userFlow].slice(userFlowSteps[userFlow].indexOf(stepToStartAtWhenEditing));
      setEditableSteps(newEditableSteps);
    }
  };

  const goToSpecificCreationStep = (chosenStepIndex) => {
    // check to see if you can proceed to this step
    if (stepsForbiddenToReturnTo.includes(userFlowSteps[userFlow][chosenStepIndex])) {
      return notification.warning({
        message: disallowedReturnMessage,
      });
    }
    if (mode === "add") {
      if (chosenStepIndex >= editableSteps.length)
        return notification.warning({
          message: "Please complete all previous steps before advancing.",
        });
      setCreationStep(userFlowSteps[userFlow][chosenStepIndex]);
    } else {
      setCreationStep(userFlowSteps[userFlow][chosenStepIndex]);
    }
    setCurrentStepIndex(chosenStepIndex);
  };

  const iconForStep = (step) => {
    if (mode === "edit") return <StyledCheckIcon />;
    return completedSteps.includes(step) ? <StyledCheckIcon /> : <StyledCloseIcon />;
  };

  const nextStepButtons = (nextStepFunction, form, disabled = false, last = false) => {
    const nextButton = (
      <BbotButton
        type={"primary"}
        disabled={disabled}
        data-test-id={"next-save-button"}
        onClick={() => {
          if (form) {
            form
              .validateFields()
              .then(() => {
                goToNextCreationStep(nextStepFunction);
              })
              .catch(() => {});
          } else {
            goToNextCreationStep(nextStepFunction);
          }
        }}
      >
        {last ? "Save" : "Next"}
      </BbotButton>
    );
    return mode === "add" ? (
      <NextStepButtonsWhenAdding>
        <BbotButton type={"default"} onClick={() => resetFunction()}>
          Cancel
        </BbotButton>
        {nextButton}
      </NextStepButtonsWhenAdding>
    ) : (
      <NextStepButtonsWhenEditing>
        <div>
          <BbotButton type={"default"} onClick={() => resetFunction()}>
            Cancel
          </BbotButton>
        </div>
        <div>
          {!last && (
            <BbotButton
              type={"primary"}
              onClick={() => {
                editFunction();
                setShowConfirmationModal(true);
              }}
            >
              Save Promo
            </BbotButton>
          )}
          {nextButton}
        </div>
      </NextStepButtonsWhenEditing>
    );
  };
  return (
    <div>
      <StyledSteps
        current={currentStepIndex}
        className={"margin-top-3 margin-bottom-6"}
        size={"small"}
        onChange={(stepIndex) => goToSpecificCreationStep(stepIndex)}
      >
        {userFlowSteps[userFlow]?.map((step, i) => (
          <Steps.Step key={i} icon={iconForStep(step)} title={stepContents[step].title} />
        ))}
      </StyledSteps>
      <hr />
      {userFlow && (
        <WizardBreadcrumbs>
          <ActiveWizardBreadcrumbs>
            {userFlow && (
              <SingleWizardBreadcrumb lighter={false}>{userFlowsPrettyNames[userFlow]}</SingleWizardBreadcrumb>
            )}
            {secondBreadcrumbToDisplay && (
              <SingleWizardBreadcrumb lighter={true}>{secondBreadcrumbToDisplay}</SingleWizardBreadcrumb>
            )}
          </ActiveWizardBreadcrumbs>
          <WizardBreadcrumbsBackButton onClick={() => goToPreviousCreationStep()}>
            Go Back <i className="zmdi zmdi-arrow-left" />
          </WizardBreadcrumbsBackButton>
        </WizardBreadcrumbs>
      )}
      <AddPromoContentContainer className={"margin-top-3 margin-bottom-2"}>
        {icon && <Row className={"margin-bottom-3"}>{icon}</Row>}
        {stepContents[creationStep].content}
        {nextStepButtons(
          stepContents[creationStep].nextStepFunction,
          stepContents[creationStep].form,
          stepContents[creationStep].disabled,
          userFlow ? userFlowSteps[userFlow].indexOf(creationStep) + 1 === userFlowSteps[userFlow].length : false
        )}
      </AddPromoContentContainer>
      <BbotModal
        visible={showConfirmationModal}
        title={confirmationTitle}
        onCancel={() => setShowConfirmationModal(false)}
        onOk={() => {
          confirmationFunction();
          setShowConfirmationModal(false);
        }}
        okButtonProps={{
          "data-test-id": "confirm-create",
        }}
      >
        {confirmationDescription}
      </BbotModal>
    </div>
  );
};

const AddPromoContentContainer = styled.div`
  padding: 24px;
  border-radius: 10px;
  box-shadow: 10px 10px 10px lightgrey;
  background-color: white;
`;

const NextStepButtonsWhenAdding = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 25px;
  .ant-btn {
    width: 100%;
    margin: 2px;
  }
`;

const NextStepButtonsWhenEditing = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 25px;
  .ant-btn {
    margin: 5px;
  }
`;

const WizardBreadcrumbs = styled.div`
  padding: 4px 12px 4px 4px;
  border-radius: 8px;
  font-size: smaller;
  background-color: #f5faff;
  border: 1px solid #84caff;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const ActiveWizardBreadcrumbs = styled.div`
  background-color: #eff8ff;
  mix-blend-mode: multiply;
  border-radius: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 2px;
  color: #175cd3;
`;

const SingleWizardBreadcrumb = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 2px 10px;
  background-color: ${(props) => (props.lighter ? "#FFFFFF" : "#EFF8FF")};
  border-radius: 16px;
`;

const WizardBreadcrumbsBackButton = styled.div`
  color: #175cd3;
  padding: 2px;
  &:hover {
    font-weight: bold;
    cursor: pointer;
  }
`;

const StyledCheckIcon = styled(CheckCircleOutlined)`
  color: var(--color-primary__regular);
  font-size: 20px;
`;

const StyledCloseIcon = styled(CloseCircleOutlined)`
  font-size: 20px;
`;

const StyledSteps = styled(Steps)`
  &&&.ant-steps {
    .ant-steps-item:first-child {
      flex-grow: 0;
      flex-shrink: 0;
      flex-basis: auto;
    }

    .ant-steps-item-container {
      outline: none;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .ant-steps-item-title {
      color: black;
      font-size: 14px;
      text-align: center;
    }

    .ant-steps-item-active .ant-steps-item-title {
      font-weight: bold;
    }

    .ant-steps-item-title:after {
      background-color: var(--color-primary__regular) !important;

      &:hover {
        background-color: var(--color-primary__regular) !important;
      }
    }

    .ant-steps-item {
      white-space: normal;
    }

    .ant-steps-item:not(:first-child) {
      .ant-steps-item-title::before {
        position: absolute;
        top: 12px;
        right: 105%;
        display: block;
        width: 9999px;
        height: 1px;
        background-color: var(--color-primary__regular);
        content: "";
      }
    }
  }
`;

BbotWizard.propTypes = {
  userFlowSteps: PropTypes.object.isRequired,
  stepContents: PropTypes.object.isRequired,
  userFlow: PropTypes.string,
  firstStep: PropTypes.string,
  mode: PropTypes.string,
  resetFunction: PropTypes.func.isRequired,
  confirmationTitle: PropTypes.string.isRequired,
  confirmationDescription: PropTypes.string.isRequired,
  confirmationFunction: PropTypes.func.isRequired,
  setUserFlow: PropTypes.func,
  editFunction: PropTypes.func,
  stepToStartAtWhenEditing: PropTypes.string,
  stepsForbiddenToReturnTo: PropTypes.array,
  userFlowsPrettyNames: PropTypes.object,
  secondBreadcrumbToDisplay: PropTypes.string,
  icon: PropTypes.node,
  disallowedReturnMessage: PropTypes.string,
  isSelectedCustomerFlaggedForDovetail: PropTypes.bool.isRequired,
};

export default BbotWizard;

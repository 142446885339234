import {
  MENU_LINKS,
  SALES_LINKS,
  OWNER_LINKS,
  INTEGRATION_LINKS,
  ADMIN_LINKS,
  SUPER_ADMIN_LINKS,
  BBOT_ADMIN_ONLY_URLS,
} from "./paths";

import { Icon } from "@doordash/design-language";

type LinksType = {
  name: string;
  testId?: string;
  link?: string;
  iconClass?: string;
  id?: string;
  googleIcon?: string;
  reactLink?: boolean;
  shouldCheckLinkWhitelist?: boolean;
};

export const filterByName = (linksToDisplay: string[], linksCollection: LinksType[]) =>
  linksCollection.filter((linkCollection: LinksType) => linksToDisplay.includes(linkCollection.name));

export const removeDeprecatedLinks = (linksToDisplay: string[]) => ({
  MENU_LINK_CONFIG: filterByName(linksToDisplay, MENU_LINKS),
  SALES_LINK_CONFIG: filterByName(linksToDisplay, SALES_LINKS),
  OWNER_LINK_CONFIG: filterByName(linksToDisplay, OWNER_LINKS),
  INTEGRATION_LINK_CONFIG: filterByName(linksToDisplay, INTEGRATION_LINKS),
  ADMIN_LINK_CONFIG: filterByName(linksToDisplay, ADMIN_LINKS),
  SUPER_ADMIN_LINK_CONFIG: filterByName(linksToDisplay, SUPER_ADMIN_LINKS),
  BBOT_ADMIN_ONLY_LINKS: filterByName(linksToDisplay, BBOT_ADMIN_ONLY_URLS),
});

export const isMobile = window.innerWidth < 768;

export enum DoveTailIcons {
  Info = Icon.Types.HelpCircleLine,
  Warning = Icon.Types.WarningTriangleLine,
}

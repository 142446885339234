import React from "react";

import axios from "axios";
import ReactFilestack from "filestack-react";
import { FILESTACK_API_KEY, EMAIL_VALIDATION_REGEX } from "Constants";
import { BBOT_DJANGO_SERVER } from "Config";
import {
  Row,
  Col,
  BbotTable,
  Breadcrumbs,
  notification,
  TableEditDeleteIcons,
  BbotButton,
  Form,
  Radio,
  Progress,
  BbotAlert,
  BbotTag,
  BbotAccordion,
  Collapse,
  PageLoadingSpinner,
  TextInput,
  CheckboxInput,
  NumberInput,
  DateTimePickerInput,
  EmailInput,
  SelectInput,
  BbotWizard,
} from "bbot-component-library";
import { DateTime } from "luxon";
import styled from "styled-components";

import { useEffect, useState } from "react";
import { CloudUploadOutlined, LoadingOutlined } from "@ant-design/icons";
import Discount from "images/custom-icons/PromotionIcons/Discount";
import PromoCode from "images/custom-icons/PromotionIcons/PromoCode";
import Employee from "images/custom-icons/PromotionIcons/Employee";
import FirstTimeGuest from "images/custom-icons/PromotionIcons/FirstTimeGuest";
import Promotion from "images/custom-icons/PromotionIcons/Promotion";

import { generalErrorAlert, capitalizeString, splitSnakeCase, fractionToPercentage, centsToDollar } from "util/Utils";
import moment from "moment";
import {
  trackClickAddPromo,
  trackClickLegacyLink,
  trackClickPromoAdvancedSettings,
  trackClickEditPromo,
  trackClickSavePromo,
  trackClickBulkDeletePromos,
  trackSavePromoSuccess,
  trackSavePromoFailure,
} from "instrumentation/tracking/page-tracking-events";
import { getDoveTailToolTipBodyText } from "../../pages/owner-app-dashboard/dovetail";

const PromotionsPage = (props) => {
  const { selectedCustomer, userInfo, allowedCustomers, isSelectedCustomerFlaggedForDovetail } = props;

  const standardTrackingFields = {
    user_id: userInfo?.id,
    customer_id: selectedCustomer?.customer_id,
  };

  const [promotions, setPromotions] = useState([]);
  const [readOnlyPromotions, setReadOnlyPromotions] = useState([]);
  const [customerHasPOS, setCustomerHasPOS] = useState(false);
  const [posPromosByVendor, setPosPromosByVendor] = useState({});
  const [preSelectedPosPromosByVendor, setPreSelectedPosPromosByVendor] = useState({});
  const [requiredVendors, setRequiredVendors] = useState([]);
  const [addingPromotion, setAddingPromotion] = useState(false);
  const [editingPromotion, setEditingPromotion] = useState(false);
  const [userFlow, setUserFlow] = useState("");
  const [discountAmountForDisplay, setDiscountAmountForDisplay] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const [promoToAdd, setPromoToAdd] = useState({}); // final source of truth for adding promotions

  // Forms related variables used across creation wizard
  const [showDisplayNameForGuest, setShowDisplayNameForGuest] = useState(false);
  const [discountDetailsForm] = Form.useForm();
  const [discountDetailsFormInitialValues, setDiscountDetailsFormInitialValues] = useState({});
  const [showRequireMinimumPurchase, setShowRequireMinimumPurchase] = useState(false);
  const [typeOfDiscount, setTypeOfDiscount] = useState("fixed");
  const [promoCodesForm] = Form.useForm();
  const [promoDetailsForm] = Form.useForm();
  const [promoDetailsFormInitialValues, setPromoDetailsFormInitialValues] = useState({});
  const [discountLimitationsMode, setDiscountLimitationsMode] = useState("unlimited");
  const [showLimitDates, setShowLimitDates] = useState(false);
  const [showVendorsSelections, setShowVendorsSelections] = useState(false);
  const [showCommonEmailWarning, setShowCommonEmailWarning] = useState(false);
  const [employeesForm] = Form.useForm();
  const [regexStringsForm] = Form.useForm();
  const [vendorsForm] = Form.useForm();

  // CSV Upload Related state variables
  const [promoCodeCSVUploadStatus, setPromoCodeCSVUploadStatus] = useState("");
  const [promoCodeCSVFilename, setPromoCodeCSVFilename] = useState("");
  const [promoCodeCSVUploadErrors, setPromoCodeCSVUploadErrors] = useState([]);

  const [employeeCSVUploadStatus, setEmployeeCSVUploadStatus] = useState("");
  const [employeeCSVFilename, setEmployeeCSVFilename] = useState("");
  const [employeeCSVUploadErrors, setEmployeeCSVUploadErrors] = useState([]);

  const legacyLink =
    BBOT_DJANGO_SERVER +
    (userInfo?.role === "admin" ? "/superuser/console/#!/edit/promotions" : "/owner/console/#!/edit/promotions");

  const userFlowsMap = {
    promoCode: ["typeOfDiscount", "discountDetails", "promotionDetails", "promoCodes", "vendors"],
    employee: [
      "typeOfDiscount",
      "typeOfUserDiscount",
      "discountDetails",
      "promotionDetails",
      "usersAndEmployees",
      "vendors",
    ],
    firstTimeGuest: ["typeOfDiscount", "typeOfUserDiscount", "discountDetails", "vendors"],
    advanced: ["typeOfDiscount", "discountDetails", "promotionDetails", "promoCodes", "usersAndEmployees", "vendors"],
  };

  const uploadStatusMap = {
    complete: "",
    enqueued: "active",
    failed: "exception",
    "": "",
  };

  const promoTagTypeColorMap = {
    promo_code: "",
    new_customer: "green",
    vip: "default",
    advanced: "red",
  };

  const userFlowToDisplayNameMap = {
    promoCode: "Promo Code",
    employee: "User / Employee Discount",
    firstTimeGuest: "New Customer",
    advanced: "Advanced",
  };

  const doveTailToolTopBodyText = getDoveTailToolTipBodyText(
    "Promos + Discounts",
    isSelectedCustomerFlaggedForDovetail
  );

  useEffect(() => {
    let newInitialValues = {};
    if (editingPromotion) {
      newInitialValues = {
        max_checkouts_per_day: promoToAdd?.max_checkouts_per_day || null,
        max_checkouts_per_week: promoToAdd?.max_checkouts_per_week || null,
        max_checkouts_per_month: promoToAdd?.max_checkouts_per_month || null,
        max_total_checkouts: promoToAdd?.max_total_checkouts || null,
        minimum_pretax_cents: centsToDollar(promoToAdd?.minimum_pretax_cents) || 0,
        require_minimum_pretax: showRequireMinimumPurchase,
        promo_start_date: moment(promoToAdd?.promo_start_date),
        promo_end_date: moment(promoToAdd?.promo_end_date),
      };
    } else if (addingPromotion) {
      newInitialValues = {
        max_checkouts_per_day: null,
        max_checkouts_per_week: null,
        max_checkouts_per_month: null,
        max_total_checkouts: null,
        minimum_pretax_cents: null,
        require_minimum_pretax: showRequireMinimumPurchase,
        promo_start_date: moment(),
        promo_end_date: moment().add(7, "d"),
      };
    }
    setPromoDetailsFormInitialValues(newInitialValues);
    promoDetailsForm.setFieldsValue(newInitialValues);
  }, [editingPromotion, addingPromotion, selectedCustomer]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    let newInitialValues = {};
    if (editingPromotion) {
      newInitialValues = {
        name_for_owner: promoToAdd?.name_for_owner || "",
        name_for_customer: promoToAdd?.name_for_customer || "",
        different_name_displayed_for_guest: showDisplayNameForGuest,
        fraction_multiplier_effect: fractionToPercentage(1 - promoToAdd?.fraction_multiplier_effect),
        cents_off_cart: centsToDollar(promoToAdd?.cents_off_cart || 0),
      };
    } else if (addingPromotion) {
      newInitialValues = {
        name_for_owner: "",
        name_for_customer: "",
        different_name_displayed_for_guest: showDisplayNameForGuest,
        fraction_multiplier_effect: null,
        cents_off_cart: null,
      };
    }
    setDiscountDetailsFormInitialValues(newInitialValues);
    discountDetailsForm.setFieldsValue(newInitialValues);
  }, [editingPromotion, addingPromotion, selectedCustomer]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    // This handles async updates to preselecting pos promos to vendors when editing a promo
    if (promoToAdd.participating_vendors && Object.keys(preSelectedPosPromosByVendor).length > 0) {
      promoToAdd.participating_vendors.forEach((vendor) => {
        if (!vendor.pos_promo && preSelectedPosPromosByVendor[vendor.customer.customer_id]) {
          vendor.pos_promo = preSelectedPosPromosByVendor[vendor.customer.customer_id];
        }
      });
    }
  }, [preSelectedPosPromosByVendor, promoToAdd]);

  useEffect(() => {
    if (!selectedCustomer) return;
    getPromotionsForCustomer();
    resetAddPromoWizard();
    setCustomerHasPOS(
      Object.keys(selectedCustomer.integrations).some((integration) =>
        ["toast", "omnivore", "omnivore_direct", "omnivore_3", "revel"].includes(integration)
      )
    );
  }, [selectedCustomer]); // eslint-disable-line react-hooks/exhaustive-deps

  const getPromotionsForCustomer = async () => {
    if (!selectedCustomer || !userInfo) return;
    setIsLoading(true);
    try {
      const res = await axios.get("/api/getAllPromotions");
      const allPromotions = res.data.promoData
        .filter((promo) => ["new_customer", "promo_code", "vip", "advanced"].includes(promo.promo_type))
        .map((promo) => {
          if (promo.type === "promo_code") {
            promo.promo_code = promo.promo_codes[0].code;
          }
          return promo;
        });
      setPromotions(allPromotions.filter((promo) => promo.customer_id === selectedCustomer.customer_id));
      setReadOnlyPromotions(allPromotions.filter((promo) => promo.customer_id !== selectedCustomer.customer_id));
      setRequiredVendors(res.data.requiredVendors);
      const posPromosByVendorsToSet = {};
      Object.entries(res.data.posPromosByVendor || {}).forEach(([vendorId, promos]) => {
        posPromosByVendorsToSet[vendorId] = promos.map((promo) => {
          promo.external_id = promo.external_id ? promo.external_id : undefined;
          promo.name = !promo["external_id"]
            ? "Unlinked (will not appear on ticket)"
            : `${promo.name} ${getPromoDescription(promo)} [${promo.external_id}]`;
          return promo;
        });
      });
      setPosPromosByVendor(posPromosByVendorsToSet);
    } catch (error) {
      generalErrorAlert(error, "There was a problem retrieving your promotions. Please refresh and try again.", true);
    } finally {
      setIsLoading(false);
    }
  };

  const getPromoDescription = (promo) => {
    const percent = promo["fraction"] !== null ? `${100 - promo["fraction"] * 100}% off` : null;
    const amount = promo["cents_off"] !== null ? `${promo["cents_off"]} cents off` : null;
    const open = promo["open"] ? "Open" : null;
    const effects = [percent, amount, open].filter((s) => s);
    return effects.length > 0 ? `(${effects.join(", ")})` : "(No effect)";
  };

  const fetchPOSPromosForCustomer = async (customerId, associatedPromo = null) => {
    try {
      const res = await axios.get("/api/getPosPromos?customer_id=" + customerId);
      setPosPromosByVendor((old_values) => {
        return {
          ...old_values,
          [customerId]: res.data.posPromos
            .filter((promo) => promo.external_id)
            .map((promo) => {
              promo.name = `${promo.name} ${getPromoDescription(promo)} [${promo.external_id}]`;
              return promo;
            }),
        };
      });
      if (associatedPromo) {
        const posPromo = res.data.posPromos.find(
          (promo) => promo.external_id === associatedPromo.integrations[promo.source][customerId].external_id
        );
        setPreSelectedPosPromosByVendor((oldValues) => {
          return {
            ...oldValues,
            [customerId]: posPromo,
          };
        });
      }
    } catch (error) {
      generalErrorAlert(error, "Trouble fetching POS Promo. Please try again.", true);
    }
  };

  const addNewPromotion = async () => {
    trackClickSavePromo(standardTrackingFields);
    const payload = await cleanPromoForBackend();
    if (payload === null) return;
    const endpoint = addingPromotion ? "/api/addPromotion" : "/api/editPromotion";
    try {
      await axios.post(endpoint, payload);
      notification.success({
        message: addingPromotion ? "Successfully created new promo." : "Successfully edited promo.",
      });
      trackSavePromoSuccess({
        ...standardTrackingFields,
        editing: !addingPromotion,
      });
      await getPromotionsForCustomer();
      resetAddPromoWizard();
    } catch (error) {
      generalErrorAlert(error, "Trouble adding promotion. Please try again.", true);
      trackSavePromoFailure({
        ...standardTrackingFields,
        error_message: error.errorCode || "Trouble adding promotion. Please try again.",
      });
    }
  };

  const cleanUnselectedLimitationValues = (cleanPromoJson) => {
    if (discountLimitationsMode === "day") {
      cleanPromoJson.max_checkouts_per_week = 0;
      cleanPromoJson.max_checkouts_per_month = 0;
      cleanPromoJson.max_total_checkouts = 0;
    } else if (discountLimitationsMode === "week") {
      cleanPromoJson.max_checkouts_per_day = 0;
      cleanPromoJson.max_checkouts_per_month = 0;
      cleanPromoJson.max_total_checkouts = 0;
    } else if (discountLimitationsMode === "month") {
      cleanPromoJson.max_checkouts_per_day = 0;
      cleanPromoJson.max_checkouts_per_week = 0;
      cleanPromoJson.max_total_checkouts = 0;
    } else if (discountLimitationsMode === "guest") {
      cleanPromoJson.max_checkouts_per_day = 0;
      cleanPromoJson.max_checkouts_per_week = 0;
      cleanPromoJson.max_checkouts_per_month = 0;
    } else {
      // if discountLimitationsMode === "unlimited"
      cleanPromoJson.max_checkouts_per_day = 0;
      cleanPromoJson.max_checkouts_per_week = 0;
      cleanPromoJson.max_checkouts_per_month = 0;
      cleanPromoJson.max_total_checkouts = 0;
    }
    return cleanPromoJson;
  };

  const cleanPromoForBackend = async () => {
    let cleanPromo = JSON.parse(JSON.stringify(promoToAdd)); // deep copy of promoToAdd to send to backend
    const keys = Object.keys(cleanPromo);
    if (editingPromotion) cleanPromo.promotion_id = promoToAdd.promotion_id;
    if (typeOfDiscount === "percent") cleanPromo.cents_off_cart = 0;
    if (typeOfDiscount === "fixed") cleanPromo.fraction_multiplier_effect = 1;

    cleanPromo.owner_limited_dates = showLimitDates;
    if (!keys.includes("whitelisted_patrons")) cleanPromo.whitelisted_patrons = [];
    cleanPromo.whitelisted_patrons = cleanPromo.whitelisted_patrons.map((patron) => {
      return {
        email: patron,
      };
    });
    cleanPromo.promo_type =
      userFlow === "promoCode"
        ? "promo_code"
        : userFlow === "employee"
        ? "vip"
        : userFlow === "advanced"
        ? "advanced"
        : "new_customer";
    cleanPromo.patron_must_be_signed_in = userFlow !== "promoCode";
    cleanPromo = cleanUnselectedLimitationValues(cleanPromo);
    if (userFlow === "firstTimeGuest") cleanPromo.max_total_checkouts = 1;
    if (!showRequireMinimumPurchase) cleanPromo.minimum_pretax_cents = 0;
    if (keys.includes("participating_vendors")) {
      cleanPromo.participating_vendors = cleanPromo.participating_vendors
        .filter(
          (vendor) =>
            showVendorsSelections || (!showVendorsSelections && requiredVendors.includes(vendor.customer.customer_id))
        )
        .map((vendor) => {
          return {
            customer_id: vendor.customer.customer_id,
            pos_promo: vendor.pos_promo,
          };
        });
    }
    if (userFlow === "promoCode" && editingPromotion) {
      cleanPromo.delete_preexisting_promo_codes = true;
    }

    // check promo codes and employees are not empty for respected user flows.
    if (userFlow === "employee" && cleanPromo.whitelisted_patrons.length < 1) {
      if (
        !cleanPromo.regex_strings_for_matching_patron_emails ||
        cleanPromo.regex_strings_for_matching_patron_emails.length < 1
      ) {
        notification.error({
          message: "Please add at least one approved user email and/or domain to your discount.",
        });
        return null;
      }
    }

    if (userFlow === "promoCode" && cleanPromo.promo_codes && cleanPromo.promo_codes.length < 1) {
      notification.error({
        message: "Please add at least one promo code to your discount.",
      });
      return null;
    }
    return cleanPromo;
  };

  const deletePromotions = async (promosToDelete) => {
    const payload = {
      promotions: promosToDelete,
    };
    try {
      await axios.post("/api/deletePromotion", payload);
      await getPromotionsForCustomer();
      notification.success({ message: "Successfully deleted promotions." });
    } catch (error) {
      generalErrorAlert(error, "Trouble deleting promotions. Please refresh and try again.", true);
    }
  };

  const savePromoEdits = async () => {
    trackClickSavePromo(standardTrackingFields);
    const allValuesToUpdate = {
      ...discountDetailsForm.getFieldsValue(),
      ...promoDetailsForm.getFieldsValue(),
    };
    await updatePromoToAdd(allValuesToUpdate, false);
  };

  const updatePromoToAdd = async (values) => {
    if (values.fraction_multiplier_effect)
      values.fraction_multiplier_effect = 1 - (values.fraction_multiplier_effect / 100).toFixed(2);
    if (values.cents_off_cart) values.cents_off_cart *= 100;
    if (values.minimum_pretax_cents) values.minimum_pretax_cents *= 100;
    if (!values.different_name_displayed_for_guest) values.name_for_customer = values.name_for_owner;
    setPromoToAdd((oldPromoValues) => {
      return {
        ...oldPromoValues,
        ...values,
      };
    });
    if (values.fraction_multiplier_effect || values.cents_off_cart)
      updatePromoDisplayAmount(
        fractionToPercentage(1 - values.fraction_multiplier_effect),
        centsToDollar(values.cents_off_cart || 0)
      );
  };

  const updatePromoDisplayAmount = (percentOff, dollarsOff) => {
    if (!percentOff) {
      setDiscountAmountForDisplay("$" + dollarsOff + " off");
    } else if (parseInt(dollarsOff) === 0) {
      setDiscountAmountForDisplay(percentOff + "% off");
    } else {
      setDiscountAmountForDisplay("$" + dollarsOff + ", " + percentOff + "% off");
    }
  };

  const updatePromoCodes = (codes, promoCodeToRemove = null) => {
    codes = codes.map((code) => code.toUpperCase());
    let newPromoCodes;
    if (promoCodeToRemove) {
      newPromoCodes = promoToAdd.promo_codes.filter((promoCode) => promoCode !== promoCodeToRemove);
    } else {
      newPromoCodes = promoToAdd.promo_codes ? promoToAdd.promo_codes.concat(codes) : codes;
    }
    setPromoToAdd((oldPromoValues) => {
      return {
        ...oldPromoValues,
        promo_codes: [...new Set(newPromoCodes)],
      };
    });
    if (!promoCodeToRemove) promoCodesForm.setFieldsValue({ promoCode: "" });
  };

  const updateEmployees = (employees, employeeToRemove = null) => {
    let newEmployees;
    if (employees.some((employee) => !EMAIL_VALIDATION_REGEX.test(employee)))
      return notification.error({
        message: "Some of the emails uploaded are not valid. Please only upload a CSV with valid emails.",
      });
    if (employeeToRemove) {
      newEmployees = promoToAdd.whitelisted_patrons.filter((email) => email !== employeeToRemove);
    } else {
      newEmployees = promoToAdd.whitelisted_patrons ? promoToAdd.whitelisted_patrons.concat(employees) : employees;
    }
    setPromoToAdd((oldPromoValues) => {
      return {
        ...oldPromoValues,
        whitelisted_patrons: [...new Set(newEmployees)],
      };
    });
    if (!employeeToRemove) employeesForm.setFieldsValue({ employee: "" });
  };

  const updateRegexStrings = (domains, domainToRemove = null) => {
    let newDomains;
    if (domainToRemove) {
      newDomains = promoToAdd.regex_strings_for_matching_patron_emails.filter((domain) => domain !== domainToRemove);
    } else {
      newDomains = promoToAdd.regex_strings_for_matching_patron_emails
        ? promoToAdd.regex_strings_for_matching_patron_emails.concat(domains)
        : domains;
    }
    setShowCommonEmailWarning(newDomains.some((domain) => ["gmail.com", "yahoo.com", "hotmail.com"].includes(domain)));
    setPromoToAdd((oldPromoValues) => {
      return {
        ...oldPromoValues,
        regex_strings_for_matching_patron_emails: [...new Set(newDomains)],
      };
    });

    if (!domainToRemove) regexStringsForm.setFieldsValue({ regex_string: "" });
  };

  const updateParticipatingVendors = (vendorId, deleteMode = false) => {
    let newVendors;
    const customerToAdd = allowedCustomers.find((customer) => customer.customer_id === vendorId);
    if (deleteMode) {
      newVendors = promoToAdd.participating_vendors.filter((vendor) => vendor.customer.customer_id !== vendorId);
    } else {
      newVendors = promoToAdd.participating_vendors
        ? promoToAdd.participating_vendors.concat([
            {
              customer: customerToAdd,
              pos_promo: undefined,
            },
          ])
        : [
            {
              customer: customerToAdd,
              pos_promo: undefined,
            },
          ];
      vendorsForm.setFieldsValue({
        vendor_id: null,
        ["vendor_pos_promos" + vendorId]: null,
      });
      // start fetching POS promo if applicable
      if (
        Object.keys(customerToAdd.integrations).some((integration) =>
          ["toast", "omnivore", "omnivore_direct", "omnivore_3", "revel"].includes(integration)
        ) &&
        !posPromosByVendor[customerToAdd.customer_id]
      ) {
        fetchPOSPromosForCustomer(customerToAdd.customer_id);
      }
    }

    setPromoToAdd((oldPromoValues) => {
      return {
        ...oldPromoValues,
        participating_vendors: newVendors,
      };
    });
  };

  const addPOSPromoToVendor = (vendorId, posPromoToAdd) => {
    const newVendors = promoToAdd.participating_vendors.map((vendor) => {
      if (vendor.customer.customer_id === vendorId) {
        vendor.pos_promo = posPromoToAdd;
      }
      return vendor;
    });
    setPromoToAdd((oldPromoValues) => {
      return {
        ...oldPromoValues,
        participating_vendors: newVendors,
      };
    });
  };

  const uploadPromosCSV = async (data, type) => {
    // type is employees or promo_codes
    const payload = {
      filestackResponse: data,
      userInputKey: type,
    };

    if (data.filesUploaded.length > 0) {
      const filename = data.filesUploaded[0].filename;
      if (type === "promo_codes") setPromoCodeCSVFilename(filename);
      else if (type === "employees") setEmployeeCSVFilename(filename);
    }

    try {
      const res = await axios.post("/api/startPromoCsvImport", payload);
      let resultKey = res.data.results;
      const checkResults = setInterval(() => {
        axios.get("/api/getImportResult", { params: { result_key: resultKey } }).then((results) => {
          if (type === "promo_codes") setPromoCodeCSVUploadStatus(results.data.status);
          else if (type === "employees") setEmployeeCSVUploadStatus(results.data.status);
          if (results.data.resultDict !== undefined && results.data.resultDict.errorMessages.length > 0) {
            if (type === "promo_codes") {
              setPromoCodeCSVUploadStatus("failed");
              setPromoCodeCSVUploadErrors(results.data.resultDict.errorMessages);
            } else if (type === "employees") {
              setEmployeeCSVUploadStatus("failed");
              setEmployeeCSVUploadErrors(results.data.resultDict.errorMessages);
            }
            clearInterval(checkResults);
          }
          if (results.data.status === "complete") {
            if (type === "promo_codes") updatePromoCodes(results.data.resultDict.rows);
            else if (type === "employees") updateEmployees(results.data.resultDict.rows);
            resultKey = undefined;
            clearInterval(checkResults);
          } else if (results.data.status === "failed") {
            if (type === "promo_codes")
              setPromoCodeCSVUploadErrors([
                "Internal server error while polling results for promo codes, please contact bb-support@doordash.com",
              ]);
            else if (type === "employees")
              setEmployeeCSVUploadErrors([
                "Internal server error while polling results for employees list, please contact bb-support@doordash.com",
              ]);
            resultKey = undefined;
            clearInterval(checkResults);
          }
        });
      }, 2000);
    } catch (error) {
      generalErrorAlert(error, "Trouble uploading CSV. Please try again.", true);
    }
  };

  const resetAddPromoWizard = () => {
    // Resets all fields necessary to start wizard from scratch.
    setAddingPromotion(false);
    setEditingPromotion(false);
    setPromoToAdd({});
    setUserFlow("");
    setShowLimitDates(false);
    setShowDisplayNameForGuest(false);
    setDiscountLimitationsMode("unlimited");
    setTypeOfDiscount("fixed");
    setShowRequireMinimumPurchase(false);
    setShowVendorsSelections(false);
    setPromoCodeCSVUploadStatus("");
    setPromoCodeCSVFilename("");
    setPromoCodeCSVUploadErrors([]);
    setEmployeeCSVUploadStatus("");
    setEmployeeCSVFilename("");
    setEmployeeCSVUploadErrors([]);
    setDiscountAmountForDisplay("");
    setPreSelectedPosPromosByVendor({});
    discountDetailsForm.resetFields();
    promoDetailsForm.resetFields();
    vendorsForm.resetFields();
  };

  const setAddMode = () => {
    setPromoToAdd({
      participating_vendors: [...new Set(requiredVendors)].map((vendorId) => {
        let customer = allowedCustomers.find((customer) => customer.customer_id === vendorId);
        if (
          Object.keys(customer.integrations).some((integration) =>
            ["toast", "omnivore", "omnivore_direct", "omnivore_3", "revel"].includes(integration)
          ) &&
          !posPromosByVendor[customer.customer_id]
        ) {
          fetchPOSPromosForCustomer(customer.customer_id);
        }
        return {
          customer: customer,
        };
      }),
    });
    setAddingPromotion(true);
    setUserFlow("");
    setShowLimitDates(false);
    setShowDisplayNameForGuest(false);
    setDiscountLimitationsMode("unlimited");
    setTypeOfDiscount("fixed");
    setShowRequireMinimumPurchase(false);
    setShowVendorsSelections(customerHasPOS || [...new Set(requiredVendors)].length > 1);
    discountDetailsForm.resetFields();
    promoDetailsForm.resetFields();
    vendorsForm.resetFields();
  };

  const getDiscountLimitationModeFromPromo = (promo) => {
    if (promo.max_total_checkouts) {
      return "guest";
    } else if (promo.max_checkouts_per_day) {
      return "day";
    } else if (promo.max_checkouts_per_week) {
      return "week";
    } else if (promo.max_checkouts_per_month) {
      return "month";
    } else {
      return "unlimited";
    }
  };

  const setEditMode = (promo) => {
    const userFlow =
      promo.promo_type === "vip"
        ? "employee"
        : promo.promo_type === "new_customer"
        ? "firstTimeGuest"
        : promo.promo_type === "advanced"
        ? "advanced"
        : "promoCode";
    setEditingPromotion(true);
    promo.whitelisted_patrons = promo.whitelisted_patrons.map((patron) => patron.email);
    promo.participating_vendors = promo.vendors.map((vendor) => {
      const customer = allowedCustomers.find((customer) => vendor.customer_id === customer.customer_id);
      if (
        Object.keys(customer.integrations).some((integration) =>
          ["toast", "omnivore", "omnivore_direct", "omnivore_3", "revel"].includes(integration)
        )
      ) {
        fetchPOSPromosForCustomer(customer.customer_id, promo);
      }
      return {
        customer: customer,
      };
    });
    promo.promo_codes = promo.promo_codes.map((promoCode) => promoCode.code);
    setPromoToAdd(promo);
    setUserFlow(userFlow);
    setShowLimitDates(promo.owner_limited_dates);
    setShowDisplayNameForGuest(promo.name_for_owner !== promo.name_for_customer);
    setDiscountLimitationsMode(() => getDiscountLimitationModeFromPromo(promo));
    setTypeOfDiscount(
      promo.cents_off_cart && promo.fraction_multiplier_effect && promo.fraction_multiplier_effect !== 1
        ? "both"
        : promo.cents_off_cart
        ? "fixed"
        : "percent"
    );
    setShowRequireMinimumPurchase(Boolean(promo.minimum_pretax_cents));
    setShowVendorsSelections(promo.vendors.length > 1 || customerHasPOS);
    updatePromoDisplayAmount(
      fractionToPercentage(1 - promo.fraction_multiplier_effect),
      centsToDollar(promo.cents_off_cart || 0)
    );
  };

  const chooseOverallUserFlowStep = () => {
    return (
      <div>
        <h4 className={"margin-bottom-1"}>What do you want to create?</h4>
        {isSelectedCustomerFlaggedForDovetail && (
          <BbotAlert className="margin-bottom-1" message={doveTailToolTopBodyText} type="warning" showIcon />
        )}
        <p>Discounts or promo codes can be applied to orders and will be applied to the guest's checkout screen.</p>
        <UserFlowOption
          onClick={() => setUserFlow("promoCode")}
          selected={userFlow === "promoCode"}
          data-test-id={"promo-code-flow"}
        >
          <Row>
            <Col span={1}>
              <PromoCode />
            </Col>
            <UserFlowOptionColumn span={22}>
              <h5>Generate Promo Code</h5>
              <span>These can be used by any guest or employee</span>
            </UserFlowOptionColumn>
          </Row>
        </UserFlowOption>
        {/* If customer is flagged for dovetail do not allow discounts to be configured */}
        {!isSelectedCustomerFlaggedForDovetail && (
          <UserFlowOption
            onClick={() => setUserFlow("employee")} // doesn't matter here whether you set employee or firstTimeGuest, behavior is the same
            selected={userFlow === "employee"}
            data-test-id={"discount-flow"}
          >
            <Row>
              <Col span={1}>
                <Discount />
              </Col>
              <UserFlowOptionColumn span={22}>
                <h5>Discount</h5>
                <span>
                  This can only be used by logged in guests or employees or first time guests with an account.
                </span>
              </UserFlowOptionColumn>
            </Row>
          </UserFlowOption>
        )}

        <AdvancedUserFlowOption
          onClick={() => {
            trackClickPromoAdvancedSettings(standardTrackingFields);
            setUserFlow("advanced");
          }}
          selected={userFlow === "advanced"}
        >
          Access Advanced Settings <i className="zmdi zmdi-arrow-right" />
        </AdvancedUserFlowOption>
      </div>
    );
  };

  const chooseSpecificUserFlowStep = () => {
    return (
      <div>
        <h4 className={"margin-bottom-1"}>Who is the discount for?</h4>
        <UserFlowOption
          onClick={() => setUserFlow("employee")}
          selected={userFlow === "employee"}
          data-test-id={"employee-flow"}
        >
          <Row>
            <Col span={1}>
              <Employee />
            </Col>
            <UserFlowOptionColumn span={22}>
              <h5>Employees and Approved Guests</h5>
              <span>This can only be used by logged in guests or employees.</span>
            </UserFlowOptionColumn>
          </Row>
        </UserFlowOption>
        <UserFlowOption
          onClick={() => setUserFlow("firstTimeGuest")}
          selected={userFlow === "firstTimeGuest"}
          data-test-id={"new-guest-flow"}
        >
          <Row>
            <Col span={1}>
              <FirstTimeGuest />
            </Col>
            <UserFlowOptionColumn span={22}>
              <h5>First-Time Guests</h5>
              <span>Only applies if they create an account!</span>
            </UserFlowOptionColumn>
          </Row>
        </UserFlowOption>
      </div>
    );
  };

  const discountDetailsStep = () => {
    if (!selectedCustomer) return;
    return (
      <>
        {Object.keys(selectedCustomer.integrations).some((integration) => integration === "toast") && (
          <BbotAlert
            type={"warning"}
            className={"margin-bottom-1"}
            message={
              "In order for this promotion to work in Toast, ensure you have a Toast check discount with 'Bbot' in its name. And, ensure the percentage matches what you intend to offer. For example, to give a 20% discount, first create a 'Bbot 20% off' discount in Toast, configured to give a fixed 20% off to the whole check. Then create a 20% off promo here."
            }
          />
        )}
        <h4 className={"margin-bottom-1"}>What do you want to name your discount?</h4>
        <p>This is how you will view your discount when managing all promos and discounts.</p>
        <Form
          name={"discountDetails"}
          form={discountDetailsForm}
          initialValues={discountDetailsFormInitialValues}
          onFinish={updatePromoToAdd}
          layout={"vertical"}
        >
          <TextInput
            id={"name-for-owner"}
            data-test-id={"name-for-owner"}
            name={"name_for_owner"}
            label={"Discount Name"}
            required={true}
          />
          <CheckboxInput
            id={"different-name-displayed-for-guest"}
            data-test-id={"different-name-displayed-for-guest"}
            name={"different_name_displayed_for_guest"}
            label={"Different name displayed to guest?"}
            onChange={(e) => {
              setShowDisplayNameForGuest(e.target.checked);
            }}
          />
          {showDisplayNameForGuest && (
            <TextInput
              id={"name-for-customer"}
              data-test-id={"name-for-customer"}
              name={"name_for_customer"}
              label={"Name Displayed to Guest"}
              required={true}
            />
          )}
          <hr />
          <h4 className={"margin-bottom-1"}>How much would you like to discount from the order?</h4>
          <Radio.Group
            onChange={(e) => setTypeOfDiscount(e.target.value)}
            value={typeOfDiscount}
            className={"margin-bottom-2"}
          >
            <Radio value={"fixed"} data-test-id={"fixed"}>
              Fixed
            </Radio>
            <Radio value={"percent"} data-test-id={"percent"}>
              Percent
            </Radio>
            <Radio value={"both"} data-test-id={"both"}>
              Both
            </Radio>
          </Radio.Group>
          {(typeOfDiscount === "percent" || typeOfDiscount === "both") && (
            <NumberInput
              id={"percent-discount"}
              data-test-id={"percent-discount"}
              name={"fraction_multiplier_effect"}
              label={"Discount Percent"}
              min={1}
              max={100}
              required={true}
              addonAfter={"%"}
            />
          )}
          {(typeOfDiscount === "fixed" || typeOfDiscount === "both") && (
            <NumberInput
              id={"fixed-discount"}
              data-test-id={"fixed-discount"}
              name={"cents_off_cart"}
              label={"Fixed Discount ($)"}
              required={true}
              min={1}
              addonBefore={"$"}
            />
          )}
        </Form>
      </>
    );
  };

  const addPromoCodesStep = () => {
    return (
      <>
        <h4 className={"margin-bottom-1"}>Which promo codes should activate your promotion?</h4>
        <p>
          Create custom promo code names and/or upload a CSV with a list of promo codes that will apply to this
          promotion.
        </p>
        <BbotAccordion>
          <Collapse.Panel header={"Upload a CSV"} key={1}>
            <ReactFilestack
              apikey={FILESTACK_API_KEY}
              actionOptions={{
                accept: ["csv"],
                maxSize: 1024 * 1024,
                maxFiles: 1,
              }}
              customRender={({ onPick }) => (
                <CSVUploadButton onClick={onPick} variant={"outline-primary"} className={"margin-bottom-2"}>
                  <CloudIcon />
                  <h5>Click to Upload</h5>
                  <div>CSV format necessary (max 1 MB)</div>
                </CSVUploadButton>
              )}
              onSuccess={(data) => uploadPromosCSV(data, "promo_codes")}
              onError={(err) => {
                console.error(err);
                notification.error({
                  message: "Trouble uploading promo codes. Please refresh and try again.",
                });
              }}
            />
            <CSVUploadInformation>
              <h5>{promoCodeCSVFilename || "No File Selected"}</h5>
              <span>
                Upload Status: {promoCodeCSVUploadStatus ? capitalizeString(promoCodeCSVUploadStatus) : "N/A"}
              </span>
              <Progress
                percent={promoCodeCSVUploadStatus ? 100 : 0}
                showInfo={false}
                status={uploadStatusMap[promoCodeCSVUploadStatus]}
              />
              {promoCodeCSVUploadErrors.map((error, i) => (
                <BbotAlert key={i} type={"error"} message={error} className={"margin-top-1"} />
              ))}
            </CSVUploadInformation>
          </Collapse.Panel>
          <Collapse.Panel
            header={<span data-test-id={"enter-promo-codes-tab"}>Enter Promo Codes Individually</span>}
            key={2}
          >
            <Form
              name={"promoCodes"}
              form={promoCodesForm}
              onFinish={(values) => updatePromoCodes([values.promoCode])}
              layout={"vertical"}
            >
              {promoToAdd.promo_codes && promoToAdd.promo_codes.length > 0 && (
                <>
                  {promoToAdd.promo_codes.map((code, i) => (
                    <Row key={i}>
                      <Col span={19}>
                        <TextInput
                          id={"promoCode" + i}
                          name={"promoCode" + i}
                          label={"Promo Code"}
                          disabled={true}
                          required={false}
                          placeholder={code.toUpperCase()}
                        />
                      </Col>
                      <SideButtonColumn span={4} offset={1}>
                        <BbotButton type={"default"} danger onClick={() => updatePromoCodes([], code)}>
                          Remove
                        </BbotButton>
                      </SideButtonColumn>
                    </Row>
                  ))}
                </>
              )}
              <Row>
                <Col span={19}>
                  <TextInput
                    id={"promo-code"}
                    data-test-id={"promo-code"}
                    name={"promoCode"}
                    label={"Add Promo Code"}
                    required={true}
                  />
                </Col>
                <SideButtonColumn span={4} offset={1}>
                  <BbotButton type={"primary"} htmlType={"submit"} data-test-id={"add-promo-code"}>
                    Enter
                  </BbotButton>
                </SideButtonColumn>
              </Row>
            </Form>
          </Collapse.Panel>
        </BbotAccordion>
      </>
    );
  };

  const promotionDetailsStep = () => {
    return (
      <>
        <h4 className={"margin-bottom-1"}>How is your discount used?</h4>
        <Form
          name={"promotionDetails"}
          form={promoDetailsForm}
          initialValues={promoDetailsFormInitialValues}
          onFinish={updatePromoToAdd}
          layout={"vertical"}
        >
          <CheckboxInput
            id={"require-minimum-purchase"}
            data-test-id={"require-minimum-purchase"}
            name={"require_minimum_pretax"}
            label={"Require Minimum Purchase"}
            onChange={(e) => {
              setShowRequireMinimumPurchase(e.target.checked);
            }}
          />
          {showRequireMinimumPurchase === true && (
            <NumberInput
              id={"minimum-purchase-amount"}
              data-test-id={"minimum-purchase-amount"}
              name={"minimum_pretax_cents"}
              label={"Minimum Purchase Amount ($)"}
              required={true}
              addonBefore={"$"}
            />
          )}
          <hr />
          <h4 className={"margin-bottom-1"}>What limitations would you like to set?</h4>
          <StyledRadioGroup
            onChange={(e) => setDiscountLimitationsMode(e.target.value)}
            value={discountLimitationsMode}
            className={"margin-bottom-1"}
          >
            <Radio value={"unlimited"} className={"margin-bottom-1"} data-test-id={"unlimited"}>
              Discount can be used unlimited times per guest
            </Radio>
            <br />
            <Radio value={"guest"} className={"margin-bottom-1"} data-test-id={"guest"}>
              Discount can be used a limited number of times
            </Radio>
            <br />
            {discountLimitationsMode === "guest" && (
              <Row className={"margin-top-1"}>
                <Col span={24}>
                  <NumberInput
                    id={"max-total-checkouts"}
                    data-test-id={"max-total-checkouts"}
                    name={"max_total_checkouts"}
                    label={"How many times can a guest use this discount?"}
                    min={1}
                    max={1000}
                    required={true}
                  />
                </Col>
              </Row>
            )}
            <Radio value={"day"} className={"margin-bottom-1"} data-test-id={"day"}>
              Discount can be used a limited number of times per day.
            </Radio>
            <br />
            {discountLimitationsMode === "day" && (
              <Row className={"margin-top-1"}>
                <Col span={24}>
                  <NumberInput
                    id={"max-checkouts-per-day"}
                    data-test-id={"max-checkouts-per-day"}
                    name={"max_checkouts_per_day"}
                    label={"How many times a day can a guest use this discount?"}
                    min={1}
                    max={1000}
                    required={true}
                  />
                </Col>
              </Row>
            )}
            <Radio value={"week"} className={"margin-bottom-1"} data-test-id={"week"}>
              Discount can be used a limited number of times per calendar week (Monday - Sunday).
            </Radio>
            <br />
            {discountLimitationsMode === "week" && (
              <Row className={"margin-top-1"}>
                <Col span={24}>
                  <NumberInput
                    id={"max-checkouts-per-week"}
                    data-test-id={"max-checkouts-per-week"}
                    name={"max_checkouts_per_week"}
                    label={"How many times a week can a guest use this discount?"}
                    min={1}
                    max={1000}
                    required={true}
                  />
                </Col>
              </Row>
            )}
            <Radio value={"month"} className={"margin-bottom-1"} data-test-id={"month"}>
              Discount can be used a limited number of times per calendar month.
            </Radio>
            <br />
            {discountLimitationsMode === "month" && (
              <Row className={"margin-top-1"}>
                <Col span={24}>
                  <NumberInput
                    id={"max-checkouts-per-month"}
                    data-test-id={"max-checkouts-per-month"}
                    name={"max_checkouts_per_month"}
                    label={"How many times a month can a guest use this discount?"}
                    min={1}
                    max={1000}
                    required={true}
                  />
                </Col>
              </Row>
            )}
          </StyledRadioGroup>
          <hr />
          <h4 className={"margin-bottom-1"}>When does this discount apply?</h4>

          <StyledRadioGroup
            onChange={(e) => setShowLimitDates(e.target.value)}
            value={showLimitDates}
            className={"margin-bottom-1"}
          >
            <Radio value={false} className={"margin-bottom-1"}>
              Discount does not expire
            </Radio>
            <br />
            <Radio value={true} className={"margin-bottom-1"}>
              Discount can be used for a limited time
            </Radio>
            <br />
            {showLimitDates && (
              <Row className={"margin-top-1"}>
                <Col span={11}>
                  <DateTimePickerInput
                    id={"promo-start-date"}
                    name={"promo_start_date"}
                    label={"Start Date / Time"}
                    format={"YYYY-MM-DD hh:mm a"}
                    showTime={{ use12Hours: true }}
                    required={true}
                  />
                </Col>
                <Col span={11} offset={2}>
                  <DateTimePickerInput
                    id={"promo-end-date"}
                    name={"promo_end_date"}
                    label={"End Date / Time"}
                    format={"YYYY-MM-DD hh:mm a"}
                    showTime={{ use12Hours: true }}
                    required={true}
                    rules={[
                      {
                        type: "date",
                        transform(value) {
                          return DateTime.fromISO(value?.format()).toSeconds();
                        },
                        min: DateTime.fromISO(promoDetailsForm.getFieldValue("promo_start_date")?.format()).toSeconds(),
                        message: "End Date must be on or after Start Date",
                      },
                    ]}
                  />
                </Col>
              </Row>
            )}
          </StyledRadioGroup>
        </Form>
      </>
    );
  };

  const addUsersAndEmployeesStep = () => {
    return (
      <>
        <h4 className={"margin-bottom-1"}>Who will be able to use your discount?</h4>
        <p>
          Upload a CSV with employees or approved guests, manually add guests, and/or select a domain URL that this
          discount will apply to.
        </p>
        <BbotAccordion>
          <Collapse.Panel header={"Upload a CSV"} key={1}>
            <ReactFilestack
              apikey={FILESTACK_API_KEY}
              actionOptions={{
                accept: ["csv"],
                maxSize: 1024 * 1024,
                maxFiles: 1,
              }}
              customRender={({ onPick }) => (
                <CSVUploadButton onClick={onPick} variant={"outline-primary"} className={"margin-bottom-2"}>
                  <CloudIcon />
                  <h5>Click to Upload</h5>
                  <div>CSV format necessary (max 1 MB)</div>
                </CSVUploadButton>
              )}
              onSuccess={(data) => uploadPromosCSV(data, "employees")}
              onError={(err) => {
                console.error(err);
                notification.error({
                  message: "Trouble uploading employees list. Please refresh and try again.",
                });
              }}
            />
            <CSVUploadInformation>
              <h5>{employeeCSVFilename || "No File Selected"}</h5>
              <span>Upload Status: {employeeCSVUploadStatus ? capitalizeString(employeeCSVUploadStatus) : "N/A"}</span>
              <Progress
                percent={employeeCSVUploadStatus ? 100 : 0}
                showInfo={false}
                status={uploadStatusMap[employeeCSVUploadStatus]}
              />
              {employeeCSVUploadErrors.map((error, i) => (
                <BbotAlert key={i} type={"error"} message={error} className={"margin-top-1"} />
              ))}
            </CSVUploadInformation>
          </Collapse.Panel>
          <Collapse.Panel
            header={<span data-test-id={"add-emails-tab"}>Invite Individual Guests by Email</span>}
            key={2}
          >
            <Form
              name={"employees"}
              form={employeesForm}
              onFinish={(values) => updateEmployees([values.employee])}
              layout={"vertical"}
            >
              {promoToAdd.whitelisted_patrons && promoToAdd.whitelisted_patrons.length > 0 && (
                <>
                  {promoToAdd.whitelisted_patrons.map((email, i) => (
                    <Row key={i}>
                      <Col span={19}>
                        <EmailInput
                          id={"employee" + i}
                          name={"employee" + i}
                          label={"Email Address"}
                          disabled={true}
                          required={false}
                          placeholder={email}
                        />
                      </Col>
                      <SideButtonColumn span={4} offset={1}>
                        <BbotButton type={"default"} danger onClick={() => updateEmployees([], email)}>
                          Remove
                        </BbotButton>
                      </SideButtonColumn>
                    </Row>
                  ))}
                </>
              )}
              <Row>
                <Col span={19}>
                  <EmailInput
                    id={"employee"}
                    data-test-id={"employee"}
                    name={"employee"}
                    label={"Add Email Address"}
                    required={true}
                  />
                </Col>
                <SideButtonColumn span={4} offset={1}>
                  <BbotButton type={"primary"} htmlType={"submit"} data-test-id={"add-email"}>
                    Enter
                  </BbotButton>
                </SideButtonColumn>
              </Row>
            </Form>
          </Collapse.Panel>
          <Collapse.Panel
            header={<span data-test-id={"add-domains-tab"}>Approve Email Domains to Use Discount</span>}
            key={3}
          >
            {showCommonEmailWarning && (
              <BbotAlert
                type={"warning"}
                message={
                  "Be aware that you have entered a very common email domain (gmail, yahoo, or hotmail). Many people will be able to use your discount."
                }
                className={"margin-bottom-1"}
              />
            )}
            <Form
              name={"regexStrings"}
              form={regexStringsForm}
              onFinish={(values) => updateRegexStrings([values.regex_string])}
              layout={"vertical"}
              className={"margin-top-2"}
            >
              {promoToAdd.regex_strings_for_matching_patron_emails &&
                promoToAdd.regex_strings_for_matching_patron_emails.length > 0 && (
                  <>
                    {promoToAdd.regex_strings_for_matching_patron_emails.map((domain, i) => (
                      <Row key={i}>
                        <Col span={19}>
                          <TextInput
                            id={"regex-string" + i}
                            name={"regex_string" + i}
                            label={"Email Domain URL"}
                            disabled={true}
                            required={false}
                            placeholder={domain}
                            addonBefore={"@"}
                          />
                        </Col>
                        <SideButtonColumn span={4} offset={1}>
                          <BbotButton type={"default"} danger onClick={() => updateRegexStrings([], domain)}>
                            Remove
                          </BbotButton>
                        </SideButtonColumn>
                      </Row>
                    ))}
                  </>
                )}
              <Row>
                <Col span={19}>
                  <TextInput
                    id={"regex-string"}
                    data-test-id={"regex-string"}
                    name={"regex_string"}
                    label={"Add Email Domain URL"}
                    addonBefore={"@"}
                    required={true}
                    regex={/[a-zA-Z0-9.-]+.[a-zA-Z]{2,4}$/}
                    regexValidationMessage={"Must be a valid email ending"}
                  />
                </Col>
                <SideButtonColumn span={4} offset={1}>
                  <BbotButton type={"primary"} htmlType={"submit"} data-test-id={"add-domain"}>
                    Enter
                  </BbotButton>
                </SideButtonColumn>
              </Row>
            </Form>
          </Collapse.Panel>
        </BbotAccordion>
      </>
    );
  };

  const selectVendorsStep = () => {
    return (
      <>
        <h4 className={"margin-bottom-1"}>Which vendors should also use this discount?</h4>
        <StyledRadioGroup
          onChange={(e) => setShowVendorsSelections(e.target.value)}
          value={showVendorsSelections}
          className={"margin-bottom-1"}
        >
          <Radio value={false} className={"margin-bottom-1"} data-test-id={"no-vendor-selection"}>
            This discount only applies to my checkouts
          </Radio>
          <br />
          <Radio value={true} className={"margin-bottom-1"} data-test-id={"select-vendors"}>
            Discount applies to other participating vendors
          </Radio>
          <br />
          {showVendorsSelections && (
            <Form
              name={"vendors"}
              form={vendorsForm}
              onFinish={(values) => updateParticipatingVendors(values.vendor_id)}
              layout={"vertical"}
            >
              {promoToAdd.participating_vendors && promoToAdd.participating_vendors.length > 0 && (
                <>
                  {promoToAdd.participating_vendors.map((vendor, i) =>
                    Object.keys(vendor.customer.integrations).some((integration) =>
                      ["toast", "omnivore", "omnivore_direct", "omnivore_3", "revel"].includes(integration)
                    ) ? (
                      <Row key={i}>
                        <Col span={9}>
                          <TextInput
                            id={"vendor_id" + i}
                            name={"vendor_id" + i}
                            label={"Participating Vendor"}
                            disabled={true}
                            required={false}
                            placeholder={vendor.customer.name_for_owner}
                          />
                        </Col>
                        <Col span={9} offset={1}>
                          <SelectInput
                            id={"vendor_pos_promos" + i}
                            name={"vendor_pos_promos" + vendor.customer.customer_id}
                            label={"Vendor POS Promotion"}
                            required={false}
                            options={
                              posPromosByVendor[vendor.customer.customer_id]
                                ? posPromosByVendor[vendor.customer.customer_id].map((promo) => {
                                    return {
                                      value: promo.value,
                                      label: promo.name,
                                    };
                                  })
                                : []
                            }
                            disabled={!posPromosByVendor[vendor.customer.customer_id]}
                            placeholder={
                              preSelectedPosPromosByVendor[vendor.customer.customer_id] ? (
                                preSelectedPosPromosByVendor[vendor.customer.customer_id].name
                              ) : [vendor.customer.customer_id] ? (
                                "Select POS Promo"
                              ) : (
                                <StyledLoadingOutlined />
                              )
                            }
                            onChange={(index) =>
                              addPOSPromoToVendor(
                                vendor.customer.customer_id,
                                posPromosByVendor[vendor.customer.customer_id][index]
                              )
                            }
                          />
                        </Col>
                        <SideButtonColumn span={4} offset={1}>
                          <BbotButton
                            type={"default"}
                            danger
                            disabled={selectedCustomer.customer_id === vendor.customer.customer_id}
                            onClick={() => updateParticipatingVendors(vendor.customer.customer_id, true)}
                          >
                            Remove
                          </BbotButton>
                        </SideButtonColumn>
                      </Row>
                    ) : (
                      <Row key={i}>
                        <Col span={19}>
                          <TextInput
                            id={"vendor_id" + i}
                            name={"vendor_id" + i}
                            label={"Participating Vendor"}
                            disabled={true}
                            required={false}
                            placeholder={vendor.customer.name_for_owner}
                          />
                        </Col>
                        <SideButtonColumn span={4} offset={1}>
                          <BbotButton
                            type={"default"}
                            danger
                            disabled={selectedCustomer.customer_id === vendor.customer.customer_id}
                            onClick={() => updateParticipatingVendors(vendor.customer.customer_id, true)}
                          >
                            Remove
                          </BbotButton>
                        </SideButtonColumn>
                      </Row>
                    )
                  )}
                </>
              )}
              <Row>
                <Col span={19}>
                  <SelectInput
                    id={"vendor"}
                    data-test-id={"vendor"}
                    name={"vendor_id"}
                    label={"Participating Vendor"}
                    options={allowedCustomers
                      .filter((customer) =>
                        promoToAdd.participating_vendors
                          ? !promoToAdd.participating_vendors.some(
                              (vendor) => vendor.customer.customer_id === customer.customer_id
                            )
                          : true
                      )
                      .map((customer) => {
                        return {
                          value: customer.customer_id,
                          label: customer.name_for_owner,
                        };
                      })}
                    showSearch
                    filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  />
                </Col>
                <SideButtonColumn span={4} offset={1}>
                  <BbotButton type={"primary"} htmlType={"submit"} data-test-id={"add-vendor"}>
                    Enter
                  </BbotButton>
                </SideButtonColumn>
              </Row>
            </Form>
          )}
        </StyledRadioGroup>
      </>
    );
  };

  const wizardStepsMap = {
    typeOfDiscount: {
      title: "Choose Type of Discount",
      content: chooseOverallUserFlowStep(),
      nextStepFunction: () => {},
      disabled: userFlow === "",
    },
    typeOfUserDiscount: {
      title: "Choose Type of User Discount",
      content: chooseSpecificUserFlowStep(),
      nextStepFunction: () => {},
      disabled: userFlow === "",
    },
    discountDetails: {
      title: "Discount Details",
      content: discountDetailsStep(),
      nextStepFunction: () => updatePromoToAdd(discountDetailsForm.getFieldsValue()),
      form: discountDetailsForm,
    },
    promotionDetails: {
      title: "Promotion Details",
      content: promotionDetailsStep(),
      nextStepFunction: () => updatePromoToAdd(promoDetailsForm.getFieldsValue()),
      form: promoDetailsForm,
    },
    usersAndEmployees: {
      title: "Add Users / Employees",
      content: addUsersAndEmployeesStep(),
      nextStepFunction: () => {},
      disabled: !promoToAdd.whitelisted_patrons && !promoToAdd.regex_strings_for_matching_patron_emails,
    },
    promoCodes: {
      title: "Add Promo Codes",
      content: addPromoCodesStep(),
      nextStepFunction: () => {},
      disabled: !promoToAdd.promo_codes,
    },
    vendors: {
      title: "Select Vendors",
      content: selectVendorsStep(),
      nextStepFunction: () => {},
      disabled: false,
    },
  };
  // If customer is flagged for dovetail do not allow discounts to be configured
  const buttonText = isSelectedCustomerFlaggedForDovetail ? "Add New Promo" : "Add New Promo or Discount";

  const getPromotionsHomePage = () => {
    return (
      <>
        <Row className={"margin-bottom-4"}>
          <Col span={24}>
            <BbotButton
              type={"primary"}
              data-test-id={"add-promo"}
              onClick={() => {
                trackClickAddPromo(standardTrackingFields);
                setAddMode();
              }}
            >
              {buttonText}
            </BbotButton>
          </Col>
        </Row>
        <hr />
        <Row className={"margin-top-4"}>
          <Col span={24}>
            <BbotTable
              id={"promotions-table"}
              title={"Your Promotions"}
              data-test-id={"promotions-table"}
              columns={[
                "Promotion Name",
                "Type",
                "Percent Discount",
                "Fixed Discount",
                "Promo Codes",
                "Vendors",
                "Actions",
              ]}
              data={promotions.map((promo) => ({
                key: promo.promotion_id,
                promotion_name: promo.name_for_owner,
                type: (
                  <BbotTag color={promoTagTypeColorMap[promo.promo_type]}>
                    {splitSnakeCase(promo.promo_type).toUpperCase()}
                  </BbotTag>
                ),
                percent_discount: fractionToPercentage(1 - promo.fraction_multiplier_effect) + "%",
                fixed_discount: "$" + centsToDollar(promo.cents_off_cart || 0),
                promo_codes: promo?.promo_codes
                  .filter((promoCode) => promoCode.code)
                  .map((promoCode) => promoCode.code.toUpperCase())
                  .join(", "),
                vendors: promo.vendors.map((vendor) => vendor.name_for_admin).join(", "),
                actions: (
                  <TableEditDeleteIcons
                    onEdit={() => {
                      trackClickEditPromo(standardTrackingFields);
                      setEditMode(promo);
                    }}
                  />
                ),
              }))}
              interactive={true}
              allowBulkDeletion={true}
              onBulkDelete={(promosToDelete) => {
                trackClickBulkDeletePromos(standardTrackingFields);
                deletePromotions(
                  promosToDelete.map((promo) => {
                    return { promotion_id: promo };
                  })
                );
              }}
            />
          </Col>
        </Row>
        <Row className={"margin-top-4"}>
          <Col span={24}>
            <BbotTable
              id={"promotions-table"}
              title={
                <>
                  Promotions You Are Participating In <BbotTag color={"red"}>View Only</BbotTag>
                </>
              }
              columns={["Promotion Name", "Type", "Percent Discount", "Fixed Discount", "Promo Codes", "Vendors"]}
              data={readOnlyPromotions.map((promo) => ({
                key: promo.promotion_id,
                promotion_name: promo.name_for_owner,
                type: (
                  <BbotTag color={promoTagTypeColorMap[promo.promo_type]}>
                    {splitSnakeCase(promo.promo_type).toUpperCase()}
                  </BbotTag>
                ),
                percent_discount: fractionToPercentage(1 - promo.fraction_multiplier_effect) + "%",
                fixed_discount: "$" + centsToDollar(promo.cents_off_cart || 0),
                promo_codes: promo?.promo_codes
                  .filter((promoCode) => promoCode.code)
                  .map((promoCode) => promoCode.code.toUpperCase())
                  .join(", "),
                vendors: promo.vendors.map((vendor) => vendor.name_for_admin).join(", "),
              }))}
              interactive={false}
              allowBulkDeletion={false}
              allowBulkEdit={false}
            />
          </Col>
        </Row>
      </>
    );
  };

  return (
    <div className={"margin-x-5"}>
      <Breadcrumbs name={"Promotions"} link={"/promotions"} />
      <Row>
        <Col span={24}>
          <div className="margin-bottom-4">
            <h2 className={"margin-bottom-1"}>
              Manage Promotions and Discounts for{" "}
              <span className={"color-primary__dark"}>{selectedCustomer?.customer_name}</span>
            </h2>
            <p>Create promo codes and discounts for customers and employees when placing orders at your restaurant.</p>
            <BbotAlert
              message={
                <span>
                  We've updated our promotions and discounts page! We'll retire the old page soon but you can{" "}
                  <a href={legacyLink} onClick={() => trackClickLegacyLink()}>
                    view it here.
                  </a>
                </span>
              }
            />
          </div>
        </Col>
      </Row>
      {isLoading ? (
        <Row>
          <Col span={2} offset={11}>
            <PageLoadingSpinner />
          </Col>
        </Row>
      ) : addingPromotion || editingPromotion ? (
        <BbotWizard
          userFlowSteps={userFlowsMap}
          setUserFlow={setUserFlow}
          stepContents={wizardStepsMap}
          userFlow={userFlow}
          mode={addingPromotion ? "add" : editingPromotion ? "edit" : null}
          resetFunction={() => resetAddPromoWizard()}
          stepToStartAtWhenEditing={"discountDetails"}
          stepsForbiddenToReturnTo={["typeOfDiscount", "typeOfUserDiscount"]}
          firstStep={"typeOfDiscount"}
          confirmationTitle={"Save promo?"}
          confirmationDescription={"Are you sure you want to save this promo?"}
          confirmationFunction={() => addNewPromotion()}
          editFunction={() => savePromoEdits()}
          secondBreadcrumbToDisplay={discountAmountForDisplay}
          userFlowsPrettyNames={userFlowToDisplayNameMap}
          icon={<Promotion />}
          disallowedReturnMessage="Sorry, you cannot change the type of discount once chosen."
          isSelectedCustomerFlaggedForDovetail={isSelectedCustomerFlaggedForDovetail}
        />
      ) : (
        getPromotionsHomePage()
      )}
    </div>
  );
};

const UserFlowOption = styled.div`
  border: ${(props) => (props.selected ? "1px solid #84CAFF" : "1px solid lightgrey")};
  padding: 16px;
  border-radius: 5px;
  margin: 10px 10px 10px 10px;
  background: ${(props) => (props.selected ? "#EFF8FF" : "")};
  box-sizing: border-box;
  span {
    color: ${(props) => (props.selected ? "#1570EF" : "")};
    font-weight: 300;
  }

  h5 {
    font-weight: 450;
    color: ${(props) => (props.selected ? "#1849A9" : "")};
  }

  &:hover {
    border: 1px solid #84caff;
    cursor: pointer;
    span {
      color: #1570ef;
    }
    h5 {
      color: #1849a9;
    }
  }
`;

const CSVUploadButton = styled(BbotButton)`
  width: 100%;
  height: 200%;
  padding: 10px 10px 10px 10px;
  align-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  h5 {
    font-weight: 450;
    color: #1849a9;
  }
`;

const CloudIcon = styled(CloudUploadOutlined)`
  font-size: 35px;
  margin-bottom: 15px;
`;

const CSVUploadInformation = styled.div`
  border: 1px solid #2e90fa;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 16px;
`;

const SideButtonColumn = styled(Col)`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const StyledRadioGroup = styled(Radio.Group)`
  width: 100%;
`;

const StyledLoadingOutlined = styled(LoadingOutlined)`
  margin-left: 50%;
`;

const AdvancedUserFlowOption = styled.div`
  padding: 16px;
  margin: 10px 10px 10px 10px;
  color: ${(props) => (props.selected ? "#1849a9" : "")};
  font-weight: ${(props) => (props.selected ? "bold" : "normal")};
  opacity: 0.8;
  &:hover {
    font-weight: bold;
    cursor: pointer;
    color: #1849a9;
  }
`;

const UserFlowOptionColumn = styled(Col)`
  margin-left: 15px;
`;

export default PromotionsPage;

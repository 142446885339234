import React, { useEffect, useState } from "react";
import { useImmer } from "use-immer";

import "styles/customer-journey/journey.scss";

import axios from "axios";
import { withRouter } from "react-router-dom";
import JourneyTask from "components/customer-journey/JourneyTask";
import { toast } from "react-toastify";
import styled from "styled-components";
import { notification } from "bbot-component-library";
import { generalErrorAlert } from "util/Utils";
import {
  trackCompleteJourneyTask,
  trackOnboardingClickSideMenu,
  trackOnboardingError,
} from "instrumentation/tracking/page-tracking-events";

import Button from "@doordash/component-button";
import { Colors, Icon, Text, Inset, Spacing, InlineChildren } from "@doordash/design-language";
import { useToast } from "@doordash/component-toast";
import MarkTaskCompletedButton from "./MarkTaskCompletedButton";

import BbotLogoBlack from "assets/svgs/bbot-logo-black.svg";
import { TASK_WIDGET_INFO } from "components/customer-journey/JourneyTask";
import { Modal } from "@doordash/component-modal";

import HeaderBackground from "assets/imgs/marketing/header_background.png";
import Banner from "@doordash/component-banner";

const Journey = (props) => {
  const [isLoadingJourney, setIsLoadingJourney] = useState(false);
  const [tasks, updateTasks] = useImmer([]);
  const [journey, setJourney] = useState(null);
  const [showJourneyCompleteModal, setShowJourneyCompleteModal] = useState(false);
  const [selectedTaskIndex, setSelectedTaskIndex] = useState(0);
  const [bottomBannerError, setBottomBannerError] = useState(null);
  const [incompleteTaskIds, setIncompleteTaskIds] = useState([]);

  const { displayToast } = useToast();

  const {
    history,
    selectedCustomer,
    selectAllowedCustomer,
    getAllowedCustomers,
    match: {
      params: { journeyId: urlJourneyId },
    },
    allowedCustomersById,
    userInfo,
    isSelectedCustomerFlaggedForDovetail,
  } = props;

  useEffect(() => {
    const getTasks = async (journeyId) => {
      setIsLoadingJourney(true);
      try {
        const res = await axios.get("/api/journey/getJourneyTasks", {
          params: {
            journey_id: journeyId,
          },
        });
        setIsLoadingJourney(false);
        updateTasks((draft) => res.data.journey.journeytasks);
        setJourney(res.data.journey);
        await getAllowedCustomers();
        selectAllowedCustomer(res.data?.journey?.customer_id, false);

        // Find the first task that isn't completed
        const initialTaskIndex = res.data.journey.journeytasks.findIndex((task) => task.status !== "completed");
        if (initialTaskIndex >= 0) {
          setSelectedTaskIndex(initialTaskIndex);
        }
      } catch (error) {
        generalErrorAlert(
          error,
          "There was an error loading account data. If this keeps happening, please let us know.",
          true
        );
        history.push("/myjourneys");
      } finally {
        setIsLoadingJourney(false);
      }
    };

    const journeyId = urlJourneyId;
    getTasks(journeyId);
  }, [history, urlJourneyId, selectAllowedCustomer]);

  const isAdmin = userInfo?.role === "admin";
  const firstTaskPrereqCompleted =
    isAdmin ||
    !tasks.length ||
    (tasks[0].task_class === "payment_and_billing_info" && tasks[0].status === "completed") ||
    tasks[0].task_class !== "payment_and_billing_info";
  const currentTask = tasks[selectedTaskIndex];

  /**
   * Updates the local task and widget status. Used to optimistically update the UI.
   * Returns a snapshot of the task to return if db update fails.
   * @param {*} taskIndex
   * @param {*} widgetId
   * @param {*} status
   */
  const updateLocalWidgetStatus = (widgetId, status) => {
    // Declare a snapshot of tasks to reset to if db update fails.
    const taskSnapshot = { ...currentTask };

    const taskWidgets = currentTask.widgets;
    const widgetIndex = taskWidgets.findIndex((widget) => widget.id === widgetId);

    // Figure out if task should be completed.
    const otherWidgets = taskWidgets.filter((widget) => widget.id !== widgetId);
    const otherWidgetsCompleted = otherWidgets.every((widget) => widget.status === "completed");
    const taskShouldBeCompleted = status === "completed" && otherWidgetsCompleted;

    updateTasks((draft) => {
      draft[selectedTaskIndex].widgets[widgetIndex].status = status;
      if (taskShouldBeCompleted) {
        draft[selectedTaskIndex].status = "completed";
      } else {
        draft[selectedTaskIndex].status = "in_progress"; // For when widget goes from completed to in progress
      }
    });

    return {
      taskShouldBeCompleted,
      resetTaskStatus: () => {
        updateTasks((draft) => {
          draft[selectedTaskIndex] = taskSnapshot;
        });
      },
    };
  };

  /**
   * Update all widgets in the current task to have the new status
   * @param {*} status
   */
  const updateLocalTaskStatus = (status) => {
    // Declare a snapshot of tasks to reset to if db update fails.
    const taskSnapshot = { ...currentTask };

    const taskWidgets = currentTask.widgets;
    const taskShouldBeCompleted = status === "completed";

    updateTasks((draft) => {
      draft[selectedTaskIndex].status = status;
      taskWidgets.forEach((widget, index) => {
        draft[selectedTaskIndex].widgets[index].status = status;
      });
    });

    return {
      taskShouldBeCompleted,
      resetTaskStatus: () => {
        updateTasks((draft) => {
          draft[selectedTaskIndex] = taskSnapshot;
        });
      },
    };
  };

  // if no widget id is provided, all widgets in the task will be updated to the new status.
  const updateTaskStatus = async (newStatus, { widgetId = null, goToNext = true, forceCompleted = false }) => {
    // Update locally so that feedback appears instant
    const { taskShouldBeCompleted, resetTaskStatus } = !!widgetId
      ? updateLocalWidgetStatus(widgetId, newStatus)
      : updateLocalTaskStatus(newStatus);

    // If all tasks are completed, show journey complete modal
    if (
      newStatus === "completed" &&
      tasks.filter((task) => task.id !== currentTask.id).every((task) => task.status === "completed")
    ) {
      setBottomBannerError(null);
    }

    try {
      if (newStatus === "completed" && taskShouldBeCompleted && goToNext) {
        gotoNextTask();
      }

      // Update the status in the backend
      await axios.post("/api/journey/updateTaskStatus", {
        task_id: currentTask.id,
        widget_id: widgetId,
        status: newStatus,
        force_completed: forceCompleted,
      });

      // Send event to Segment
      if (newStatus === "completed") {
        trackCompleteJourneyTask({
          journey_id: journey.id,
          journey_task_id: currentTask.id,
          force_completed: forceCompleted,
        });
      }
    } catch (error) {
      console.error("Error updating task status, rolling back state change in UI...");
      notification.error({
        message: `We couldn't save your progress on the ${
          TASK_WIDGET_INFO[currentTask.task_class]?.title ?? currentTask.title
        } task. Please let us know if this keeps happening.`,
      });
      trackOnboardingError({
        error: "Update Task Status Error",
        description: "The api request to update the task status failed.",
        journey_id: journey.id,
        journey_task_id: currentTask.id,
      });
      resetTaskStatus();
    }
  };

  const markCurrentTaskCompleted = async () => updateTaskStatus("completed", { goToNext: true, forceCompleted: true });

  const taskSelected = (selectedTask, index) => {
    if (selectedTask.status === "pre_req_blocked") {
      const preReqs = tasks
        .filter((task) => {
          // pre req tasks: get tasks where pre req task id is in task ids list
          return selectedTask.prerequisite_tasks.includes(task.id);
        })
        .map((task) => TASK_WIDGET_INFO[task.task_class]?.title ?? task.title);

      toast.warn("This task is locked. To unlock, please complete the following pre-requisite tasks: " + preReqs);
      return;
    }

    if (incompleteTaskIds.includes(selectedTask.id)) {
      setIncompleteTaskIds((taskIds) => taskIds.filter((taskId) => taskId !== selectedTask.id));
    }

    trackOnboardingClickSideMenu({
      prev_journey_task_id: currentTask?.id,
      journey_task_id: tasks[index]?.id,
      journey_id: journey?.id,
    });
    setSelectedTaskIndex(index);
  };

  const gotoNextTask = () => {
    if (selectedTaskIndex + 1 !== tasks.length) {
      const nextTask = tasks[selectedTaskIndex + 1];
      taskSelected(nextTask, selectedTaskIndex + 1);
      document.querySelector("main")?.scrollTo({ top: 0 }); // eslint-disable-line no-unused-expressions
    }
  };

  const logout = () => {
    axios.post("/api/journey/logout").then((res) => {
      if (res.data.logout_success) {
        props.history.push("/login");
      }
    });
  };

  const journeyCompleteModal = (
    <Modal
      title="Congratulations, you've finished your PreBot onboarding!"
      hasCloseButton={false}
      primaryAction={{
        text: "Continue to Owner Portal",
        onClick: () => history.push("/"),
      }}
      headerImage={<img src={HeaderBackground} alt="header_background_img" />}
    >
      <Text>
        Your account manager will be in touch with next steps. In the meantime, feel free to explore your Bbot Owner
        Portal. Here, you can add additional customizations to your digital ordering experience and edit any information
        you added in PreBot. If you need help along the way, please reach out to your Bbot representative.
      </Text>
    </Modal>
  );

  const journeyTaskSkeleton = (
    <div className="task">
      <div className="task-info">
        <div className={"skeleton-title skeleton-div skeleton-div__lg margin-bottom-1"}></div>
        <div className={"skeleton skeleton-div skeleton-div__sm"}></div>
      </div>
    </div>
  );

  const getTaskCircleIcon = (task, index) => {
    if (incompleteTaskIds.includes(task.id) && index !== selectedTaskIndex) {
      return <TaskCompletedIcon type={Icon.Types.ErrorFill} color={Icon.Colors.TextError} />;
    } else if (task.status === "completed") {
      return <TaskCompletedIcon type={Icon.Types.CheckCircleFill} color={Icon.Colors.TextAccentedPrimary} />;
    } else {
      return (
        <TaskCircleContainer>
          <TaskCircle isSelected={index === selectedTaskIndex} />
        </TaskCircleContainer>
      );
    }
  };

  const taskList = (
    <div>
      {tasks.map((task, index) => (
        <div key={task.id}>
          <TaskStep
            onClick={() => {
              if (firstTaskPrereqCompleted) {
                taskSelected(tasks[index], index);
              }
            }}
            isSelected={index === selectedTaskIndex}
            data-test-id={task.task_class}
          >
            {getTaskCircleIcon(task, index)}
            <TaskTitleContainer isSelected={index === selectedTaskIndex} isError={incompleteTaskIds.includes(task.id)}>
              <Text styles={Text.Styles.Body2Emphasis}>{TASK_WIDGET_INFO[task.task_class]?.title ?? task.title}</Text>
            </TaskTitleContainer>
          </TaskStep>
          {index < tasks.length - 1 && <VerticalDivider />}
        </div>
      ))}
    </div>
  );

  return (
    <Container>
      <SideNav>
        <SidenavContainer>
          <LogoContainer>
            <img src={BbotLogoBlack} alt={"Icon"} className={"margin-bottom-3"} />
          </LogoContainer>
          {taskList}
        </SidenavContainer>

        <AccountContainer>
          <Text styles={Text.Styles.Body1Emphasis} className="customer-name">
            {journey?.customer_name}
          </Text>
          <div className="d-flex justify-content-space-between full-width">
            <Button type={Button.Types.Link} isInline onClick={logout}>
              <Text styles={Text.Styles.Caption1} color={Colors.TextSecondary}>
                Log Out
              </Text>
            </Button>

            {userInfo?.role === "admin" && (
              <Button type={Button.Types.Link} isInline onClick={() => props.history.push("/")}>
                <Text styles={Text.Styles.Caption1} color={Colors.TextSecondary}>
                  Owner Portal
                </Text>
              </Button>
            )}
          </div>
        </AccountContainer>
      </SideNav>
      <ContentContainer>
        <Content data-test-id="task-content-container">
          {isLoadingJourney
            ? journeyTaskSkeleton
            : currentTask && (
                <JourneyTask
                  task={currentTask}
                  isLastTask={selectedTaskIndex + 1 === tasks.length}
                  allowedCustomersById={allowedCustomersById}
                  journey={journey}
                  updateTaskStatus={updateTaskStatus}
                  userInfo={userInfo}
                  gotoNextTask={gotoNextTask}
                  isSelectedCustomerFlaggedForDovetail={isSelectedCustomerFlaggedForDovetail}
                  selectedCustomer={selectedCustomer}
                />
              )}
        </Content>
        <NavBar>
          <ContentContainer>
            {bottomBannerError && <Banner body={bottomBannerError} variant={Banner.Variants.Negative} />}
          </ContentContainer>

          <InlineChildren>
            {isAdmin && currentTask?.status !== "completed" && (
              <MarkTaskCompletedButton onClick={markCurrentTaskCompleted} />
            )}

            {selectedTaskIndex > 0 && (
              <Button
                isInline
                data-test-id="prev-task-btn"
                type={Button.Types.Tertiary}
                onClick={() => {
                  if (selectedTaskIndex > 0) {
                    const nextTask = tasks[selectedTaskIndex - 1];
                    taskSelected(nextTask, selectedTaskIndex - 1);
                    document.querySelector("main")?.scrollTo({ top: 0 }); // eslint-disable-line no-unused-expressions
                  }
                }}
              >
                Previous Step
              </Button>
            )}

            {selectedTaskIndex < tasks.length - 1 && (
              <Button
                isInline
                data-test-id="next-task-btn"
                onClick={() => {
                  if (!firstTaskPrereqCompleted) {
                    displayToast({
                      icon: Icon.Types.ErrorLine,
                      text: "Please complete this step before proceeding.",
                      insetFromEdge: Inset.Sizes.Medium,
                    });
                    return;
                  }

                  if (selectedTaskIndex + 1 !== tasks.length) {
                    const nextTask = tasks[selectedTaskIndex + 1];
                    taskSelected(nextTask, selectedTaskIndex + 1);
                    document.querySelector("main")?.scrollTo({ top: 0 }); // eslint-disable-line no-unused-expressions
                  }
                }}
              >
                Next Step
              </Button>
            )}

            {selectedTaskIndex === tasks.length - 1 && !isAdmin && (
              <Button
                isInline
                data-test-id="next-task-btn"
                onClick={() => {
                  if (tasks.every((task) => task.status === "completed")) {
                    setShowJourneyCompleteModal(true);
                  } else {
                    setBottomBannerError("Please finish all of the tasks to complete onboarding.");
                    setIncompleteTaskIds(tasks.filter((task) => task.status !== "completed").map((task) => task.id));
                  }
                }}
              >
                Complete Setup
              </Button>
            )}
          </InlineChildren>
        </NavBar>
      </ContentContainer>
      {!isAdmin && showJourneyCompleteModal && journeyCompleteModal}
    </Container>
  );
};

export default withRouter(Journey);

const Container = styled.div`
  display: flex;
  height: 100vh;
  background-color: white;
`;

const SideNav = styled.div`
  width: 250px;
  border-right: 1px solid #e7e7e7;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const SidenavContainer = styled.nav`
  padding: 20px 12px;
  overflow-y: auto;
  flex: 1;
`;

const AccountContainer = styled.div`
  width: 100%;
  border-top: 1px solid #e7e7e7;
  height: 84px;
  padding-left: ${Spacing.Large}px;
  padding-right: ${Spacing.Large}px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  .customer-name {
    overflow-wrap: anywhere;
  }
`;

const LogoContainer = styled.div`
  margin-left: 14px;
`;

const ContentContainer = styled.div`
  flex: 1;
`;

const NavBar = styled.div`
  height: 84px;
  padding: 0px ${Spacing.Large}px;
  gap: ${Spacing.Small}px;
  border-top: 1px solid #e7e7e7;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const TaskStep = styled.button`
  border: none;
  align-items: center;
  background-color: ${(props) => (props.isSelected ? Colors.SystemBlue10 : "transparent")};
  padding: 8px 14px;
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  width: 100%;
`;

const VerticalDivider = styled.div`
  height: 8px;
  margin-left: 25px;
  border-left: 2px solid #e7e7e7;
`;

const TaskCircleContainer = styled.div`
  padding: 2px;
`;

const TaskCircle = styled.div`
  height: 20px;
  width: 20px;
  border: 2px solid;
  border-color: ${(props) => (props.isSelected ? Colors.SystemBlue70 : Colors.TextDisabled)};
  border-radius: 100%;
  margin-right: 8px;
`;

const TaskCompletedIcon = styled(Icon)`
  margin-right: 8px;
`;

const TaskTitleContainer = styled.span`
  flex: 1;
  display: flex;
  align-items: center;
  span {
    color: ${(props) => {
      if (props.isSelected) {
        return Colors.SystemBlue70;
      } else if (props.isError) {
        return Colors.TextError;
      } else {
        return Colors.TextSecondary;
      }
    }};
  }
`;

const Content = styled.main`
  overflow: auto;
  height: calc(100vh - 84px);
`;

import React from "react";
import "styles/customer-journey/NewCustomerLogin.scss";
import axios from "axios";
import { Button, Carousel, Col, Container, Row } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import QRCode from "react-qr-code";

import { toast } from "react-toastify";
import { BBOT_DJANGO_SERVER } from "Config";
import { HISTORY_SOURCE_BBOT_OWNER_PORTAL } from "Constants";

import Slide1 from "assets/imgs/marketing/Slide-1.png";
import Slide2 from "assets/imgs/marketing/Slide-2.png";
import Slide3 from "assets/imgs/marketing/Slide-3.png";
import LogoCircle from "assets/svgs/bbot-logo-circle.svg";
import NewUserModal from "components/owner-app/modals/new-user-signup/NewUserModal";
import Loading from "@doordash/component-loading";

const MARKETING_INFO = [
  {
    key: "marketing-1",
    title: "Exploring the Possibilities of Catering",
    body: "Catering is a fulfillment type like in-venue server delivery, online ordering, driver delivery, and patron pickup. Routing can be configured so orders are addressed promptly, and both staff and guests are alerted by any status progression.",
    src: Slide1,
    url: "https://try.bbot.menu/products/catering",
  },
  {
    key: "marketing-2",
    title: "Related Redefines the Guest Experience at Hudson Yards",
    body: '"Bbot worked closely with every one of our teams, from online menu creation to figuring out logistics. We loved working with their team so much that we’ve decided to expand and add two more properties this year."',
    src: Slide2,
    url: "https://meetbbot.com/blog/related-redefines-the-guest-experience-at-hudson-yards",
  },
  {
    key: "marketing-3",
    title: "How To Keep Your Restaurant in Business During COVID-19",
    body: "There are a few preparations that a restaurant can take as it reopens to reduce the spread of the coronavirus and make its consumers and employees feel safe during these unprecedented times.",
    src: Slide3,
    url: "https://meetbbot.com/blog/how-to-keep-your-restaurant-in-business-during-covid-19",
  },
];

class NewCustomerLogin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: null,
      userExists: null,
      referrer: null,
      loginInfo: {},
      loginError: {},
      loginMode: true,
      MFALoginMode: false,
      MFASetupMode: false,
      standardLoginMode: true,
      MFAQRCode: null,
      MFAReset: null,
      newUser: {
        level: "Owner",
      },
      marketingInfo: MARKETING_INFO,
      currentSlide: MARKETING_INFO[0],
      marketingInfoIndex: 0,
      displayRegisterModal: false,
      showSignupModal: false,
      loading: false,
    };
    this.attachJourneyToEmployee = this.attachJourneyToEmployee.bind(this);
  }

  componentDidMount() {
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let customer_id = params.get("customer_id");
    let journey_id = params.get("journey_id");
    let referrer = params.get("referrer");
    let newUser = this.state.newUser;
    newUser["customer_id"] = customer_id;
    newUser["journey_id"] = journey_id;

    this.checkCustomerIdandJourneyId(customer_id, journey_id);

    this.setState({
      newUser: newUser,
      referrer: referrer,
      customer_id: customer_id,
      journey_id: journey_id,
      marketingInfoIndex: 0,
    });
  }

  async checkCustomerIdandJourneyId(customerId, journeyId) {
    if (customerId === null || journeyId === null) return false;

    const endpoint = "/api/journey/checkCustomerIdAndJourneyId";
    const data = { params: { customer_id: customerId, journey_id: journeyId } };
    let res = await axios.get(endpoint, data);
    this.setState({ customerIdAndJourneyIdValid: res.data["ids_valid"] });
  }

  wrongUrl() {
    return (
      <div>
        <h3 className="welcome">Please contact your organization leader for a valid invite link.</h3>
        <div style={{ margin: "20px 0", textAlign: "center" }}>
          <a href="/login">Back to login page</a>
        </div>
      </div>
    );
  }

  registerUserMode = () => {
    this.setState({ loginMode: false });
  };

  marketingPanel = () => {
    const { marketingInfo } = this.state;

    return (
      <Carousel
        className="marketing-carousel"
        indicators={true}
        controls={false}
        onSlide={(index) => {
          this.setState({ currentSlide: marketingInfo[index] });
        }}
      >
        {marketingInfo.map((marketingItem, index) => (
          <Carousel.Item key={index}>
            <Carousel.Caption>
              <div>
                <h3 className={"margin-bottom-2"}>{marketingItem.title}</h3>
                <p className={"margin-bottom-2"}>{marketingItem.body}</p>
                {marketingItem.url && (
                  <Button
                    className={"margin-bottom-2"}
                    variant={"outline-light"}
                    onClick={() => {
                      window.location.href = marketingItem.url;
                    }}
                  >
                    Read More
                  </Button>
                )}
              </div>
            </Carousel.Caption>
          </Carousel.Item>
        ))}
      </Carousel>
    );
  };

  loginPage = () => {
    const { marketingInfo, currentSlide, MFALoginMode, MFASetupMode, standardLoginMode } = this.state;

    return (
      <div className={"login-page-container"}>
        {marketingInfo.map((slide, index) => (
          <img
            key={index}
            alt={"background"}
            className={"background-image " + (currentSlide?.key === slide.key ? "" : " hidden")}
            src={slide.src}
          />
        ))}
        <Container fluid>
          <Row>
            <Col lg={7} className={"hidden-desktop-sm"}>
              {this.marketingPanel()}
            </Col>
            <Col lg={5} className={""}>
              <div className={"login-container"}>
                <div className="login-title margin-bottom-2">
                  <img className="icon medium margin-bottom-1" src={LogoCircle} alt={"Bbot Logo"} />
                  <h2>Owner Portal</h2>
                </div>
                {standardLoginMode && this.loginForm()}
                {MFASetupMode && this.MFASetupForm()}
                {MFALoginMode && this.MFALoginForm()}
                <div className={"footer"}>
                  {standardLoginMode && (
                    <p className={"text-x-small center color-grey-5"}>
                      <Button
                        variant={"link"}
                        className="color-grey-5 underline link-button"
                        onClick={() => {
                          this.setState({
                            MFASetupMode: true,
                            standardLoginMode: false,
                          });
                        }}
                      >
                        Set up multi-factor authentication
                      </Button>
                    </p>
                  )}
                  <p className={"text-x-small center color-grey-5"}>&#169; Bbot, Inc. 2021. All Rights Reserved.</p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    );
  };

  loginForm = () => {
    const { customer_id, journey_id, customerIdAndJourneyIdValid } = this.state;

    return (
      <div className="content">
        <form onSubmit={this.handleLogin} className={"styled-form margin-bottom-2"}>
          <Row>
            <Col md={12} className={"margin-bottom-2"}>
              <label htmlFor="username" style={{ display: "block" }}>
                Username (email)
              </label>
              <input
                className={"form-control"}
                type="text"
                name="username"
                data-test-id={"username"}
                id="username"
                onChange={this.handleLoginInputChange}
              />
            </Col>
            <Col md={12} className={"margin-bottom-2"}>
              <label htmlFor="password" style={{ display: "block" }}>
                Password
              </label>
              <input
                className={"form-control"}
                type="password"
                name="password"
                data-test-id={"password"}
                id="password"
                onChange={this.handleLoginInputChange}
              />
            </Col>
            <Col md={12}>
              <div className="form-error margin-bottom-2 center">
                <p>{this.state.loginError.passwordError}</p>
              </div>
              <div className={"margin-bottom-2"}>
                <Button className="new-customer-submit" type="submit" data-test-id={"sign in"} id={"signin-submit"}>
                  Sign in
                </Button>
              </div>
            </Col>
          </Row>
          <Row>
            <Col sm={12}>
              <p className={"text-x-small center color-grey-5"}>
                We will handle your information according to our{" "}
                <a className="color-grey-5 underline" href={"https://meetbbot.com/privacy/"}>
                  Privacy Policy
                </a>
                .
              </p>
              <p className={"text-x-small center"}>
                <a href={BBOT_DJANGO_SERVER + "/password_reset/"}>Forgot your password?</a>
              </p>
            </Col>
          </Row>
        </form>
        {customer_id && journey_id && customerIdAndJourneyIdValid && (
          <div>
            <hr />
            <Button
              variant="outline-primary"
              className="new-customer-register-btn"
              onClick={() => this.setState({ showSignupModal: true })}
              data-test-id="create-new-account-btn"
            >
              Create a new account
            </Button>
          </div>
        )}
      </div>
    );
  };

  MFASetupForm = () => {
    const { MFAQRCode } = this.state;

    return (
      <div className="content">
        <form onSubmit={this.handleMFASetup} className={"styled-form margin-bottom-2"}>
          {MFAQRCode ? (
            <div>
              <h4 className={"center margin-top-1"}>1. Scan QR Code</h4>
              <p className={"center"}>(Use Google Authenticator or another authenticator app to set up MFA)</p>
              <div className={"login-title margin-bottom-4"}>
                <QRCode value={MFAQRCode} size={100} />
              </div>
              <h4 className={"center margin-bottom-1"}>2. Enter 6-Digit Code</h4>
              <div className={"field-wrapper margin-bottom-2"}>
                <input
                  type="text"
                  placeholder="000000"
                  className={"center"}
                  maxLength={6}
                  name="totpToken"
                  id="totpToken"
                  onChange={this.handleLoginInputChange}
                />
              </div>
              <div className="form-error margin-bottom-2 center">
                <p>{this.state.loginError.MFAError}</p>
              </div>
              <div className={"margin-bottom-2 login-title"}>
                {this.state.loading ? (
                  <Loading />
                ) : (
                  <Button type="submit" id={"signin-submit"}>
                    Enter
                  </Button>
                )}
              </div>
              <p className={"text-x-small center color-grey-5"}>
                <Button
                  variant={"link"}
                  className="color-grey-5 underline link-button"
                  onClick={() => {
                    this.setState({
                      MFASetupMode: false,
                      standardLoginMode: true,
                      MFAQRCode: null,
                    });
                  }}
                >
                  Didn't Receive a Two-Factor Authentication Code?
                </Button>
              </p>
            </div>
          ) : (
            <div>
              <div className={"field-wrapper margin-bottom-2"}>
                <p className={"center"}>
                  Enter your username and password below. Then press "Get QR Code" to proceed with setting up MFA.
                </p>
                <label htmlFor="username">Username (email)</label>
                <input type="text" name="username" id="username" onChange={this.handleLoginInputChange} />
              </div>
              <div className={"field-wrapper margin-bottom-2"}>
                <label htmlFor="password">Password</label>
                <input type="password" name="password" id="password" onChange={this.handleLoginInputChange} />
              </div>
              <div className="form-error margin-bottom-2 center">
                <p>{this.state.loginError.MFAError}</p>
              </div>
              <div className={"margin-bottom-2"}>
                <Button className="new-customer-submit" type="submit" id={"signin-submit"}>
                  Get QR Code
                </Button>
              </div>
            </div>
          )}
        </form>
      </div>
    );
  };

  MFALoginForm = () => {
    return (
      <div className="content margin-top-2">
        <h4 className={"center margin-bottom-2"}>Enter 6-Digit Code</h4>
        <form onSubmit={this.handleMFALogin} className={"styled-form margin-bottom-2"}>
          <div className={"field-wrapper margin-bottom-4"}>
            <input
              type="text"
              placeholder="000000"
              name="totpToken"
              id="totpToken"
              className={"center"}
              maxLength={6}
              onChange={this.handleLoginInputChange}
            />
          </div>

          <div className="form-error margin-bottom-2 center">
            <p>{this.state.loginError.MFAError}</p>
          </div>
          <div className={"margin-bottom-2 login-title"}>
            {this.state.loading ? (
              <Loading />
            ) : (
              <Button type="submit" id={"signin-submit"}>
                Enter
              </Button>
            )}
          </div>
          <p className={"text-x-small center color-grey-5"}>
            <Button
              variant={"link"}
              className="color-grey-5 underline link-button"
              onClick={() => {
                this.handleMFAReset();
                toast.success("An email has been sent for you to re-configure MFA on your account.");
              }}
            >
              Forgot MFA Device?
            </Button>
          </p>
          <p className={"text-x-small center color-grey-5"}>
            We will handle your information according to our{" "}
            <a className="color-grey-5 underline" href={"https://meetbbot.com/privacy/"}>
              Privacy Policy
            </a>
            .
          </p>
        </form>
      </div>
    );
  };

  async attachJourneyToEmployee() {
    const { journey_id, customer_id } = this.state;
    let endpoint = "/api/journey/attachJourney";
    let res = await axios.post(endpoint, {
      journey_id: journey_id,
      customer_id: customer_id,
    });
    if (res.status === 200) {
      this.props.history.push("/");
    } else {
      console.error("Couldn't attach journey to user. Something went wrong!");
    }
  }

  handleLogin = async (e) => {
    const { referrer, loginInfo } = this.state;
    const loginData = {
      email: loginInfo["username"],
      password: loginInfo["password"],
    };
    e.preventDefault();

    // if missing username or password
    if (!loginInfo["username"] || !loginInfo["password"]) {
      return;
    }

    try {
      const res = await axios.post("/api/journey/login", loginData);
      // Log in was a success
      if (referrer === "invite") {
        this.attachJourneyToEmployee();
      } else {
        if (res.data["skip_mfa"]) {
          await this.props.loginSuccessCallback();
          if (
            this.props.history.location.state?.from_bbot_page === HISTORY_SOURCE_BBOT_OWNER_PORTAL &&
            this.props.history.location.state?.from?.pathname !== "/login"
          ) {
            this.props.history.push(this.props.history.location.state?.from?.pathname);
          } else {
            this.props.history.push("/");
          }
        } else {
          this.setState({ MFALoginMode: true, standardLoginMode: false });
        }
      }
    } catch (error) {
      //admin passwor security is important
      if (error.errorCode) {
        this.setState({ loginError: { ...this.state.loginError, passwordError: error.errorCode } });
      } else if (error.response?.data?.message) {
        const errorMessage = error.response.data?.message;
        this.setState({ loginError: { ...this.state.loginError, passwordError: errorMessage } });
      } else if (error.response && error.response.data && error.response.data.errorCode) {
        let loginError = this.state.loginError;
        loginError["passwordError"] = error.response.data.errorCode;
        this.setState({ loginError: loginError });
      } else {
        console.error(error);
        toast.error("There was an error trying to log in. Please refresh the page and try again.");
      }
    }
  };

  handleMFALogin = async (e) => {
    const { loginInfo } = this.state;
    const loginData = {
      email: loginInfo["username"],
      password: loginInfo["password"],
      totpToken: loginInfo["totpToken"],
    };

    e.preventDefault();

    try {
      this.setState({ loading: true });
      await axios.post("/api/ownerMFALogin", loginData);
      await this.props.loginSuccessCallback();
      this.props.history.push("/");
      this.setState({ loading: false });
    } catch (error) {
      this.setState({ loading: false });
      if (error.response.data && error.response.data.errorCode) {
        let loginError = this.state.loginError;
        loginError["MFAError"] = error.response.data.errorCode;
        this.setState({ loginError: loginError });
      } else {
        console.error(error);
        toast.error("There was an error trying to log in. Please refresh the page and try again.");
      }
    }
  };

  handleMFASetup = async (e) => {
    const { loginInfo } = this.state;
    const loginData = {
      email: loginInfo["username"],
      password: loginInfo["password"],
      totpToken: loginInfo["totpToken"],
    };

    const mode = loginData.totpToken ? "confirmDevice" : "createDevice";
    e.preventDefault();

    try {
      const res = await axios.post("/api/ownerMFASetup", {
        setupStep: mode,
        ...loginData,
      });
      if (mode === "confirmDevice") {
        await this.props.loginSuccessCallback();
        this.props.history.push("/");
      } else {
        this.setState({ loginError: {} });
        // mode is createDevice, meaning a QR Code was sent back
        this.setState({ MFAQRCode: res.data["config_url"] });
      }
    } catch (error) {
      if (error.response.data && error.response.data.errorCode) {
        let loginError = this.state.loginError;
        loginError["MFAError"] = error.response.data.errorCode;
        this.setState({ loginError: loginError });
      } else {
        console.error(error);
        toast.error("There was an error setting up MFA. Please refresh the page and try again.");
      }
    }
  };

  handleMFAReset = async () => {
    const { loginInfo } = this.state;
    const loginData = {
      email: loginInfo["username"],
      password: loginInfo["password"],
    };

    try {
      await axios.post("/api/ownerMFAReset", loginData);
      this.setState({ MFAReset: true });
    } catch (error) {
      console.error(error);
      toast.error("There was an error resetting MFA. Please refresh the page and try again.");
    }
  };

  handleSaveNewCustomer = async (newUserInfo) => {
    const { customer_id } = this.state;
    let newUser = {
      email: newUserInfo["email"],
      first_name: newUserInfo["first_name"],
      last_name: newUserInfo["last_name"],
      level: newUserInfo["level"],
      pin: newUserInfo["pin"],
      password: newUserInfo["password"],
      customer_id: customer_id,
    };

    try {
      const res = await axios.post("/api/journey/signup", { newUser: newUser });
      const login_success = res.data["login_success"];
      if (login_success) {
        await this.props.loginSuccessCallback();
        this.props.history.push("/");
      }
    } catch (error) {
      if (error.response.data && error.response.data.errorCode) {
        toast.error(error.response.data.errorCode);
      } else {
        toast.error(
          "We ran into a problem creating this new account. Please make sure the email has not been registered already."
        );
      }
      console.error(error);
    }
  };

  handleLoginInputChange = (e) => {
    const { loginInfo } = this.state;
    loginInfo[e.target.name] = e.target.value;
    this.setState({ loginInfo: loginInfo });
  };

  render = () => {
    const { showSignupModal, customer_id } = this.state;

    return (
      <>
        {this.loginPage()}
        {showSignupModal && (
          <NewUserModal
            customerId={customer_id}
            onClose={() => this.setState({ showSignupModal: false })}
            loginSuccessCallback={this.props.loginSuccessCallback}
          />
        )}
      </>
    );
  };
}

export default withRouter(NewCustomerLogin);

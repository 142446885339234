/* eslint-disable react-hooks/exhaustive-deps */
import { InlineChildren } from "@doordash/design-language";
import { BbotContainer } from "bbot-component-library";
import { CarouselItemOrder, LinkSectionTexts, UpsellsHeading, UpsellsList } from "./TableElements";

const UpsellsTable = ({
  cartLevelUpsellsSelected,
  currentlySelectedUpsells,
  loading,
  menuHeadings,
  menuItems,
  menus,
  noElementSelected,
  orderedUpsells,
  saveUpsells,
  selectedElement,
  setCurrentlySelectedUpsells,
  setOrderedUpsells,
  setUpsells,
  textToDisplay,
}) => (
  <BbotContainer>
    <UpsellsHeading />
    <InlineChildren>
      <LinkSectionTexts
        loading={loading}
        menuHeadings={menuHeadings}
        menus={menus}
        selectedElement={selectedElement}
        setUpsells={setUpsells}
        cartLevelUpsellsSelected={cartLevelUpsellsSelected}
        menuItems={menuItems}
      />
      <UpsellsList
        currentlySelectedUpsells={currentlySelectedUpsells}
        noElementSelected={noElementSelected}
        orderedUpsells={orderedUpsells}
        setOrderedUpsells={setOrderedUpsells}
        setCurrentlySelectedUpsells={setCurrentlySelectedUpsells}
        textToDisplay={textToDisplay}
      />
      <CarouselItemOrder orderedUpsells={orderedUpsells} saveUpsells={saveUpsells} />
    </InlineChildren>
  </BbotContainer>
);

export default UpsellsTable;
